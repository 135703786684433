import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 20vh;
  text-align: center;

  & a {
    color: inherit;
  }

  & img {
    margin-top: 5vh;
  }
`;

const ErrorScreen = () => {
  return (
    <Container>
      <h1>
        Oops, there's nothing here.. go <a href="/">Home</a> to start over?
      </h1>
      <img src="../assets/images/error.png" alt="Error Not Found" />
    </Container>
  );
};

export default ErrorScreen;
