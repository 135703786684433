import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../../assets/images/logo.png';

const OrganismKeyframe = keyframes`
    0% {
        border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
    }
    25% {
        border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
    }
    50% {
        border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
        box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
    }
    75% {
        border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
    }
`;

const breatheAnimation = keyframes`
    0% { height: 55px; width: 55px; }
    5% { height: 62px; width: 62px }
    70% { height: 55px; width: 55px; }
    100% { height: 55px; width: 55px; }
`;

const AnimationBox = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 200px;
    margin: ${(props: { margin?: string, leftPercentage?: string  }) => (!!props.margin ? props.margin : '20vh auto')};
    left: ${(props: { margin?: string, leftPercentage?: string }) => (!!props.leftPercentage ? props.leftPercentage + '%' : 'auto')};
    background-color: #54493d;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: ${OrganismKeyframe} 10s infinite;
    overflow: hidden;
`;

const Logo = styled.img`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: auto;
    animation: ${breatheAnimation} 2s infinite;
`;

const LoadingAnimation = (props: { margin?: string; leftPercentage?: string }) => {
    return (
        <AnimationBox margin={props.margin} leftPercentage={props.leftPercentage}>
            <Logo src={logo} alt="SewerMapper" />
        </AnimationBox>
    );
};

export default LoadingAnimation;
