import axios from "axios";
import { SetStateAction } from "react";

type IsCaptureRunningType = {
  setMachineState: React.Dispatch<SetStateAction<string | null>>;
}

export const IsCaptureRunning = async (event:IsCaptureRunningType) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/isrunning" : window.location.origin.replace("://", "://api.")+"/api/captures/isrunning";

  const response = await axios.get(requestUrl)
  if (response.data === false) {
    event.setMachineState("Something went wrong, please reboot the system");
  }
}
