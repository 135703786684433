import axios from "axios";

export const ApiSyncUnresolvedFiles = (token: string, userId: string,) => {
  const requestUrl = window.location.hostname === 'localhost' ? `/api/captures/getprocessed/${userId}` : window.location.origin.replace("://", "://api.")+`/api/captures/getprocessed/${userId}`;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  try {
    return axios.get(requestUrl, config);
  } catch (error) {
    console.error("Error fetching data.");
  }
};
