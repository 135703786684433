import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ApiGetAllUsers } from '../../calls/ApiGetAllUsers';
import { ApiSyncUnresolvedFiles } from '../../calls/ApiSyncUnresolvedFiles';

const HeaderButton = styled.button`
    font-size: 15px;
    color: white;
    text-align: center;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    transition-duration: 0.4s;
    text-decoration: none;
    margin-top: 10px;
    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

const FormBlock = styled.div`
    background: #26292b;
    width: 200px;
    height: 65px;
    position: absolute;

    margin-top: 10px;
    border-top: 2px solid #fa8c2a;
    border-radius: 5px;
    padding: 10px;
    left: calc(50% - 110px);
`;

const FormLabel = styled.label`
    font-size: 12px;
    margin-right: 10px;
`;

const FormSelect = styled.select`
    max-width: 130px;
`;
const FormButton = styled.button`
    font-size: 10px;
    color: white;
    text-align: center;
    padding: 8px 15px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    transition-duration: 0.4s;
    text-decoration: none;
    margin-top: 10px;
    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

const FormContainer = styled.div`
    position: relative;
`;

const SyncForm = (props: { token: string }) => {
    const [formOpen, setFormOpen] = useState(false);
    const [syncing, setSyncing] = useState(false);

    const [users, setUsers] = useState<{ id: string; username: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);

    // const [projects, setProjects] = useState<{ id: string; name: string }[]>([]);
    // const [selectedProject, setSelectedProject] = useState<string | null>(null);

    const onSync = useCallback(() => {
        if (selectedUser !== null) {
            setSyncing(true);
            ApiSyncUnresolvedFiles(props.token, selectedUser)
                ?.then(() => {
                    if (window) window.location.reload();
                })
                .catch(() => {
                    setSyncing(false);
                    alert('Error while syncing Database');
                });
        }
    }, [selectedUser]);

    useEffect(() => {
        ApiGetAllUsers(props.token)?.then(res => {
            setUsers(res);
        });
        // ApiGetProjects(props.token)?.then(res => {
        //   setProjects(res)
        // })
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            setSelectedUser(users[0].id);
        }
    }, [users]);

    // useEffect(() => {
    //   if (projects.length > 0) {
    //     setSelectedProject(projects[0].id);
    // }
    // }, [projects])

    return (
        <FormContainer>
            <HeaderButton type="button" onClick={() => setFormOpen(open => !open)}>
                Sync captures
            </HeaderButton>
            {formOpen && (
                <FormBlock>
                    {syncing ? (
                        'Syncing... Please wait'
                    ) : (
                        <>
                            <FormLabel htmlFor="user">User:</FormLabel>
                            <FormSelect
                                name="user"
                                id="user"
                                onChange={event => setSelectedUser(event.currentTarget.value)}
                            >
                                {users.map(user => {
                                    return (
                                        <option value={user.id}>
                                            {user.id} {user.username}
                                        </option>
                                    );
                                })}
                            </FormSelect>
                            {/* <FormLabel htmlFor="project">Project:</FormLabel>
                            <FormSelect
                                name="project"
                                id="project"
                                onChange={event => setSelectedProject(event.currentTarget.value)}
                            >
                                {projects.map(project => {
                                    return (
                                        <option value={project.id}>
                                            {project.id} {project.name}
                                        </option>
                                    );
                                })}
                            </FormSelect> */}
                            <br />
                            <FormButton type="button" onClick={onSync}>
                                Sync
                            </FormButton>
                        </>
                    )}
                </FormBlock>
            )}
        </FormContainer>
    );
};

export default SyncForm;
