import axios from "axios";

export async function ApiPutCapture(capture: any, token: string) {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/" + capture.id : window.location.origin.replace("://", "://api.")+"/api/captures/" + capture.id;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  var postVariables = capture;
  try {
    await axios.put(requestUrl, postVariables, config).then(res => {
      if (res.status === 200) {
      }
    });
  } catch (error) {
    console.log(error);
  }
}
