import { GetUserFromCookie, UserType } from "../../functions/Cookie";
import {
  SET_PROGRESS,
  SET_USER,
  SET_HIDDEN,
  SET_SCREENMODE,
  SET_CAPTURESTATE,
  SET_PREVIEWNAME,
  SET_LASTCAPTURE,
  SET_DISKSPACE,
  SET_IMAGEUPLOADPROGRESS
} from "../constants/action-types";

export type InitialStoreStateType = {
  progressData: number,
  imageUploadProgress: number,
  captureState: string,
  user: UserType,
}

const initialState = {
  progressData: 0,
  imageUploadProgress: 0,
  captureState: "CAPTURE_START",
  user: GetUserFromCookie(),
};

function rootReducer(state = initialState, action: {type:any, payload: any}) {
  if (action.type === SET_PROGRESS) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        progressData: action.payload
      });
    }
  }

  if (action.type === SET_USER) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        user: action.payload
      });
    }
  }

  if (action.type === SET_HIDDEN) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        navOverlayHidden: action.payload
      });
    }
  }
  if (action.type === SET_SCREENMODE) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        screenMode: action.payload
      });
    }
  }

  if (action.type === SET_CAPTURESTATE) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        captureState: action.payload
      });
    }
  }
  if (action.type === SET_PREVIEWNAME) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        preview: action.payload
      });
    }
  }
  if (action.type === SET_LASTCAPTURE) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        lastcapture: action.payload
      });
    }
  }
  if (action.type === SET_DISKSPACE) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        diskspace: action.payload
      });
    }
  }
  if (action.type === SET_IMAGEUPLOADPROGRESS) {
    if (action.payload !== null) {
      return Object.assign({}, state, {
        imageUploadProgress: action.payload
      });
    }
  }

  return state;
}

export default rootReducer;
