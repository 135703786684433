import axios from "axios";
import store from "../redux/store";
import { setProgress } from "../redux/actions/index";

export const GetProcessingProgress = async () => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/progress" : window.location.origin.replace("://", "://api.")+"/api/captures/progress";
  //@ts-ignore
  if (window) window.store = store;
  //@ts-ignore
  if (window) window.setProgress = setProgress;
  store.subscribe(() => null);
  const response = await axios.get(requestUrl)
  store.dispatch(setProgress(response));
}
