import axios from "axios";
import store from "../redux/store/index";
import { setLastCapture } from "../redux/actions";

type ApiPostCaptureType = {
  setCapture: (State: string)=>void;
}

export const ApiPostCapture = async (name: string, type: number, token: string, event: ApiPostCaptureType) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures" : window.location.origin.replace("://", "://api.")+"/api/captures";
  const config = {
    headers: { Authorization: "Bearer " + token }
  };
  const postVariables = {
    CaptureTypeId: type,
    name: name,
    location: "location"
  };
  try {
    return axios.post(requestUrl, postVariables, config).then(res => {
      if (res.status === 200) {
        const data = res.data;
        event.setCapture(data);
        store.dispatch(setLastCapture(data));
      }
    });
  } catch (error) {
    alert("Something went wrong.\n Not saved to db");
  }
}
