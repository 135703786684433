import React from "react";
import styled from "styled-components";

import LoginForm from "../components/forms/LoginForm";
import Toggle from "../components/common/Toggle";

import store from "../redux/store";
import { EmptyUserCookie } from "../functions/Cookie";
import { setUser, setHidden } from "../redux/actions/index";

const LoginContainer = styled.div`
  width: 100%;
  margin-top: 20vh;
  text-align: center;
`;
const HelpButton = styled.a`
  display: block;
  float: left;
  margin-left: 5%;
  border-radius: 50px;
  border: 1px solid #fa8c2a;
  color: #fa8c2a;
  text-decoration: none;
  font-size: 28px;
  padding: 5px 14px;
  text-align: center;
  background: #26292b;
`;

const Login = () => {
  const clearUser = () => {
    if (store.getState().user !== null) {
      store.dispatch(setUser(null));
      store.dispatch(setHidden(true));
      EmptyUserCookie();
      setUser(null);
    }
  };

  return (
    <>
      {clearUser()}
      <LoginContainer>
        <LoginForm />
        <HelpButton href="/help">?</HelpButton>
        <Toggle class="darkmode" active="Dark" items={["Light", "Dark"]} />
      </LoginContainer>
    </>
  );
};
export default Login;
