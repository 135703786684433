import React, { useState } from 'react';
import styled from 'styled-components';
import BackButton from '../common/BackButton';
import HistoryLayoutAndFilters from './HistoryLayoutAndFilters';

import Projects from './Projects';

const Button = styled.button`
    background-color: transparent;
    border: none;
    margin-top: 7px;
    cursor: pointer;
    & focus {
        outline: none;
    }
`;

const SearchBar = styled.input`
    color: #fa8c2a;
    float: right;
    margin-top: 18px;
    height: 36px;
    width: 130px;
    box-sizing: border-box;
    border: 1px solid #fa8c2a;
    border-radius: 35px;
    font-size: 16px;
    background-color: #26292b;
    background-image: url('../../assets/images/search.svg');
    background-position: 10px 5px;
    background-size: 20px;
    background-repeat: no-repeat;
    padding: 5px 20px 5px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    &:focus {
        width: 30%;
        outline: none;
    }

    @media screen and (max-width: 480px) {
        &:focus {
            width: 130px;
        }
    }
`;

const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    margin: 0px 5% 25px;
`;

const OverlayHistoryFilter = (props: { projectId: number }) => {
    const { projectId } = props;

    const [grid, setGrid] = useState(1);
    const [search, setSearch] = useState('');
    return (
        <>
            {!!projectId && <BackButton href="/" appendText="back to projects" />}
            <TopBar>
                <p>
                    <Button
                        onClick={() => {
                            setGrid(1);
                        }}
                    >
                        <img
                            src={
                                grid ? '../../assets/images/group_active.svg' : '../../assets/images/group_inactive.svg'
                            }
                            alt=""
                            width="21px"
                            height="21px"
                        />
                    </Button>
                    {!!projectId && (
                        <Button
                            onClick={() => {
                                setGrid(0);
                            }}
                        >
                            <img
                                src={
                                    grid
                                        ? '../../assets/images/list_inactive.svg'
                                        : '../../assets/images/list_active.svg'
                                }
                                alt="list"
                                width="21px"
                                height="21px"
                            />
                        </Button>
                    )}
                </p>
                <SearchBar type="text" onChange={e => setSearch(e.target.value)} />
            </TopBar>
            {!!projectId ? (
                <HistoryLayoutAndFilters grid={grid} search={search} projectId={projectId} />
            ) : (
                <Projects grid={grid} search={search} />
            )}
        </>
    );
};

export default OverlayHistoryFilter;
