import axios from "axios";

export const ApiGetAllUsers = (token: string) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/users" : window.location.origin.replace("://", "://api.")+"/api/users";
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  try {
    const response = axios.get(requestUrl, config).then(res => {
        if (res.status === 200) {
            return res.data;
          }
    });
    return response;
    
  } catch (error) {
    console.error("Error fetching data.");
  }
};
