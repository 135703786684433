import React from 'react';
import styled from 'styled-components';

import time from '../../assets/images/time.svg';
import location from '../../assets/images/location.svg';
import device from '../../assets/images/device.svg';
import defaultImage from '../../assets/images/logo.png';

import { HistoryType } from './History.type';

const CardGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 0 20px;
    grid-auto-rows: auto;
    text-align: center;
`;

const Card = styled.div`
    margin-top: 100px;
    border: 2px solid #ffa94d;
    border-radius: 5px;
    background-color: #fa8c2a;
    padding: 1em;
    color: #d9480f;
    transition: 0.3s;

    & hover {
        transform: scale(1.05);
        box-shadow: 0 10px 20px rgba(37, 33, 82, 0.12), 0 4px 8px rgba(37, 33, 82, 0.06);
    }
`;

const Image = styled.img`
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    margin-top: -90px;
    border: #ffa94d 2px solid;
    background-color: #383e40;
`;

const List = styled.ul`
    list-style: none;
    color: white;
    padding-inline-start: 0px;
    font-weight: normal;
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    margin: 15px 0px;
    padding-bottom: 10px;

    & span {
        margin: auto;
    }

    &&:nth-child(1) {
        font-weight: bold;
        color: white;
        border-bottom: none;
    }

    &:nth-child(4) {
        border-bottom: none;
        padding-bottom: 5px;
    }
`;

const Status = styled.div`
    background-color: ${props => (props.color === 'Processed' ? '#00C66B' : 'yellow')};
    color: white;
    width: 100px;
    margin: auto;
    text-align: center;
    margin-bottom: -25px;
`;

export default React.memo((props: { captures: HistoryType[]; search: string }) => {
    const captures = props.captures.map((capture: HistoryType) => {
        const status = capture.processStatus;
        if (capture.fullName.toUpperCase().indexOf(props.search.toUpperCase()) === -1) return null;
        return (
            <a key={capture.key} href={`/preview/${capture.id}`} style={{ textDecoration: 'none' }} className="block">
                <Card>
                    <Image
                        src={
                            !!capture.thumbnail
                                ? window.location.origin.replace('://', '://api.') + capture.thumbnail
                                : defaultImage
                        }
                        width="150px"
                        height="150px"
                    />
                    <List>
                        <ListItem>
                            <p>Capture : {capture.fullName}</p>
                        </ListItem>
                        <ListItem>
                            <img src={time} alt="time" width="20px" height="20px" />
                            <span>{capture.dateTime}</span>
                        </ListItem>
                        <ListItem>
                            <img src={location} alt="location" width="20px" height="20px" />
                            <span>{capture.location}</span>
                        </ListItem>
                        <ListItem>
                            <img src={device} alt="device" width="20px" height="20px" />
                            <span>{capture.deviceName}</span>
                        </ListItem>
                    </List>
                    <Status color={status}>{status}</Status>
                </Card>
            </a>
        );
    });
    return <CardGroup>{captures}</CardGroup>;
});
