import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import { ApiGetCapturesByProject } from '../../calls/ApiGetCapturesByProject';
import { ApiGetCaptureCount } from '../../calls/ApiGetCaptureCount';
import { ApiGetLoadMoreCapturesFromProject } from '../../calls/ApiGetLoadMoreCapturesFromProject';
import store from '../../redux/store';
import { GetUserFromCookie } from '../../functions/Cookie';
import { CaptureType, HistoryType, ReceivedHistoryType } from './History.type';

import HistoryBlock from './HistoryBlock';
import HistoryBlockList from './HistoryBlockList';
import Spinner from '../common/Spinner';
import LoadingAnimation from '../common/LoadingAnimation';

const Container = styled.div`
    box-sizing: border-box;
    margin: 0px 5%;
`;

const ProjectTitle = styled.h3`
    text-align: left;
    width: 100%;
    font-size: 16px;
`

const LoadMoreButton = styled.button`
    font-size: 15px;
    color: white;
    text-align: center;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin: 30px auto;
    display: block;
    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

type UserType = {
    firstname: string;
    lastname: string;
    username: string;
    id: number;
    token: string;
};

const HistoryLayout = (props: { grid: number; search: string; projectId: number }) => {
    const { grid, search, projectId } = props;

    const [user, setUser] = useState<UserType>({} as UserType);
    const [captures, setCaptures] = useState<HistoryType[]>([] as HistoryType[]);
    const [captureCount, setCaptureCount] = useState(0);
    const [projectName, setProjectName] = useState('');
    const [isLoadingMoreCaptures, setIsLoadingMoreCaptures] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);

    const onStoreChange = (currentUser: UserType) => {
        setUser(currentUser);
    };

    useEffect(() => {
        const getData = async () => {
            const currentUser = await GetUserFromCookie();
            const unsubscribe = store.subscribe(() => onStoreChange(currentUser));
            setUser(currentUser);
            const data: ReceivedHistoryType = await ApiGetCapturesByProject(projectId, currentUser.token);
            setCaptureCount(data.capturesCount);
            setCaptures(await processData(data));
            setProjectName(data.name);
            setIsLoadingInitial(false);
            return unsubscribe();
        };
        getData();
    }, []);

    const loadMoreCaptures = useCallback(async () => {
        const nextCaptures = await processData(
            await ApiGetLoadMoreCapturesFromProject(projectId, captures.length, user.token),
        );
        if (nextCaptures.length && nextCaptures.length > 0) {
            const tmpAllCaptures = [...captures, ...nextCaptures];
            setCaptures(tmpAllCaptures);
            setIsLoadingMoreCaptures(false);
        }
    }, [captures]);

    return (
        <>
            {isLoadingInitial ? (
                <Container>
                    <LoadingAnimation />
                </Container>
            ) : (
                <Container>
                    <ProjectTitle>Project: {projectName}</ProjectTitle>
                    {captures &&

                        (grid ? (
                            <HistoryBlock captures={captures} search={search} />
                        ) : (
                            <HistoryBlockList captures={captures} search={search} />
                        ))}
                </Container>
            )}

            {captures.length + 1 < captureCount && (
                <LoadMoreButton
                    type="button"
                    onClick={() => {
                        setIsLoadingMoreCaptures(true);
                        loadMoreCaptures();
                    }}
                >
                    {isLoadingMoreCaptures ? <Spinner spinnerColor="#fff" isInside /> : 'Load more'}
                </LoadMoreButton>
            )}
        </>
    );
};

export default HistoryLayout;

const processData = (capture: ReceivedHistoryType) => {
    const capturesSorted = capture.captures.sort(
        (a: CaptureType, b: CaptureType) => Date.parse(b.timeStamp) - Date.parse(a.timeStamp),
    );
    const downloadComponents = Promise.all(
        capturesSorted.map((item: CaptureType) => {
            const date = Date.parse(item.timeStamp);
            const status = item.status ? 'Processed' : 'Preview';
            const data = {
                key: item.id,
                id: item.id,
                dateTime: new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(date),
                deviceName: 'SM02-SP',
                location: item.location,
                syncStatus: status,
                processStatus: status,
                fullName: item.name,
                userId: item.userId,
                path: item.path,
                thumbnail: item.image,
            };
            return data;
        }),
    );
    return downloadComponents;
};
