import axios from 'axios';


export default async function StartProcess(directory:string, quickProcess:number){
    const requestUrl = quickProcess ? '/api/captures/quickprocess' : '/api/captures/fullprocess';
    const postVariables = {
        'path': directory,
    }
    try {
        const response = await axios.post(requestUrl, postVariables)      
    } catch (error) {
        console.log(error)
    }
}
