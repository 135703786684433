import axios from "axios";

export default async function ApiPutCaptureProcess(
  captureID: number,
  name: string,
  token: string
) {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/" + captureID : window.location.origin.replace("://", "://api.")+"/api/captures/" + captureID;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  var postVariables = { Status: 1, Name: name };
  try {
    await axios.put(requestUrl, postVariables, config).then(res => {
      if (res.status === 200) {
      }
    });
  } catch (error) {
    alert(error);
  }
}
