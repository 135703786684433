import axios from "axios";
import { SetStateAction } from "react";

type SetGpsArgumentsType = {
  lon: string;
  lat: string;
}

type GetGPSType = {
  setGpsData: React.Dispatch<SetStateAction<SetGpsArgumentsType>>;
}

export const GetGPS = async (event:GetGPSType) => {
  const requestUrl = "/get_GPS";
  const response = await axios.get(requestUrl);
  event.setGpsData(response.data)
}
