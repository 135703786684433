import { SetStateAction } from "react";
import { IsCaptureRunning } from "../calls/IsCaptureRunning";

type StartCaptureCheckType = {
  setRefreshID: React.Dispatch<SetStateAction<number>>;
  setMachineState: React.Dispatch<SetStateAction<string | null>>;
};

export const StartCaptureCheck = (event: StartCaptureCheckType) => {
  const refreshID = setInterval(IsCaptureRunning(event), 2000);
  event.setRefreshID(refreshID);
};
