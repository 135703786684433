import React, { useState, useEffect } from "react";
import CircularProgressbar from "react-circular-progressbar";
import styled from "styled-components";

import store from "../redux/store";

import "react-circular-progressbar/dist/styles.css";

const User = styled.div`
  display: inline-block;
  background-color: #333;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #fa8c2a;
  text-decoration: none;
  border-radius: 50px;
  font-size: 13px;
  cursor: pointer;
  margin: 5%;

  & svg {
    position: absolute;
    width: 40px;
    top: 18px;
    right: 59px;

    @media screen and (min-width: 767px) {
      right: 90px;
    }
  }
`;

const CircularProgressBar = () => {
  const [progress, setProgress] = useState<number>(0);
  const [user, setUser] = useState(null);

  useEffect(() => {
    // @ts-ignore
    window.store = store;
    const unsubscribe = store.subscribe(() => onStoreChange());
    const data:number = store.getState().progressData
    setProgress(data);
    setUser(store.getState().user);

    return unsubscribe;
  },[store.getState().progressData]);

  const onStoreChange = async () => {
    setUser(store.getState().user);
    setProgress(store.getState().progressData);
  };

  return (
    <>
      {user != null && (
        <User>
          <CircularProgressbar
            percentage={progress}
            background
            strokeWidth={5}
            styles={{
              background: {
                fill: "#26292B"
              },
              // Customize the path, i.e. the part that's "complete"
              path: {
                // Tweak path color:
                stroke: "#FA8C2A",
                // Tweak path to use flat or rounded ends:
                strokeLinecap: "butt",
                // Tweak transition animation:
                transition: "stroke-dashoffset 0.5s ease 0s"
              },
              // Customize the circle behind the path
              trail: {
                // Tweak the trail color:
                stroke: "#26292B"
              },
              // Customize the text
              text: {
                // Tweak text color:
                fill: "#FA8C2A",
                // Tweak text size:
                fontSize: "25px"
              }
            }}
            text={`${progress}%`}
          />
        </User>
      )}
    </>
  );
};

export default CircularProgressBar;
