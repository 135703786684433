import axios from "axios";

export async function ApiGetCapturesByProject(projectId: number, token: string) {
  const apiEndpoint = `/api/projects/${projectId}`
  const requestUrl = window.location.hostname === 'localhost' ? apiEndpoint : window.location.origin.replace("://", "://api.")+apiEndpoint;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };

  try {
    const response = await axios.get(requestUrl, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log("something went wrong fetching the data");
  }
};
