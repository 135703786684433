import Cookies from "universal-cookie";

export type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

export const SaveUser = (user: UserType) => {
  const cookies = new Cookies();
  cookies.set("user", user, {
    path: "/",
    expires: new Date(Date.now() + 86400000)
  });
};

export const GetUserFromCookie = (): UserType => {
  const cookies = new Cookies();
  return cookies.get("user");
};

export const EmptyUserCookie = () => {
  const cookies = new Cookies();
  cookies.remove("user");
};
