import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import MultiSelect from 'react-multi-select-component';
import useScreen from './hooks/useScreen';
import { ApiGetCaptures } from '../calls/ApiGetCaptures';
import { ApiGetCompanies, CompanyType } from '../calls/ApiGetCompanies';
import { CaptureType } from '../components/historycomponents/History.type';
import colors from '../styles/colors';
import { ApiPostNewProject } from '../calls/ApiPostNewProject';

const Container = styled.div`
    margin-top: 12vh;
`;

const Title = styled.h2`
    margin-top: 40px;
    color: #fa8c2a;
    text-align: center;
`;

const ErrorBanner = styled.h3`
    padding: 10px 20px;
    background-color: ${colors.lightOrange};
    color: white;
    text-align: center;
`;

const InputText = styled.input`
    color: white;
    height: 36px;
    width: 100%;
    margin-top: 8px;
    box-sizing: border-box;
    border: 1px solid #fa8c2a;
    border-radius: 35px;
    font-size: 16px;
    background-color: #26292b;
    padding: 2px 20px 2px 20px;
    transition: all 0.2s ease;
    &:focus {
        border-color: #fff;
        border-width: 2px;
        outline-width: 0;
        transition: all 0.2s ease;
    }
`;

const InputLabel = styled.label``;

const FormBlock = styled.div`
    padding: 0 40px;
    margin-top: 18px;
    @media screen and (min-width: 480px) {
        width: 50%;
    }
`;

const FormContainer = styled.div`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
`;

const SelectContainer = styled.div`
    position: relative;
    margin-top: 24px;
    padding: 0 40px;
    justify-content: center;
    .gray {
        color: ${colors.gray};
    }
    .multi-select {
        --rmsc-bg: ${colors.black};
        --rmsc-main: white;
        --rmsc-hover: ${colors.mainOrange};
        --rmsc-selected: ${colors.lightOrange};
    }
    .dropdown-container {
        background-color: ${colors.black};
        border-radius: 35px;
        border: 1px solid ${colors.mainOrange};
        color: ${colors.gray};
        &::focus-within {
            box-shadow: ${colors.mainOrange} 0 0 0 1px;
            border-color: white;
        }
        .gray {
            color: ${colors.gray};
        }
        &:focus {
            border-color: white;
        }
        .dropdown-heading {
            padding-left: 20px;
        }
        .dropdown-heading-value {
            font-size: 13px;
        }
        .dropdown-heading-dropdown-arrow {
            display: none;
        }
    }
    @media screen and (min-width: 480px) {
        flex-direction: row;
    }
`;

const CustomSelect = styled.select`
    position: relative;
    width: 100%;
    height: 40px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: ${colors.black};
    border-radius: 35px;
    border: 1px solid ${colors.mainOrange};
    color: ${colors.gray};
    appearance: none;
    background-image: url('../assets/images/SelectArrow.svg');
    background-repeat: no-repeat;
    background-size: 8px;
    background-position-x: 95%;
    background-position-y: center;
    outline: none;
    transition: all 0.2s ease;
    margin-bottom: 20px;
    &:focus {
        border-width: 2px;
        border-color: white;
        transition: all 0.2s ease;
    }
`;

const CustomMultiSelect = styled.div`
    position: relative;
    width: 100%;
    height: 36px;
`;

const StyledButton = styled.button`
    font-size: 15px;
    color: white;
    text-align: center;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin: 30px auto;
    display: block;
    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

const AddProject = () => {
    const { user, isAdmin } = useScreen();

    if (typeof window !== 'undefined') {
        if (document.querySelector('.dropdown-heading-value span')) {
            //@ts-ignore
            document.querySelector('.dropdown-heading-value span').innerHTML = 'Select captures';
        }
    }

    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [selectedCaptures, setSelectedCaptures] = useState<{ value: string; label: string }[]>([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [captures, setCaptures] = useState<CaptureType[]>([]);
    const [companies, setCompanies] = useState<CompanyType[]>([]);
    const [error, setError] = useState('');

    const addProjectCallback = useCallback(() => {
        if (!isAdmin && !!name && !!location) {
            ApiPostNewProject(
                { name, location, captures: selectedCaptures.map(caps => caps.value) },
                user.token,
            );
        } else if (isAdmin && !!name && !!location && !!selectedCompany) {
            ApiPostNewProject(
                { name, location, captures: selectedCaptures.map(caps => caps.value), companyId: selectedCompany },
                user.token,
            );
        } else {
            setError('All fields are required (except for captures)!');
        }
    }, [name, location, selectedCaptures, selectedCompany, isAdmin, user]);

    const formattedCompanies = useMemo(() => {
        return companies.map((company: CompanyType) => {
            return {
                value: company.id,
                label: company.name,
            };
        });
    }, [companies]);

    const formattedCaptures = useMemo(() => {
        return captures.map((capture: CaptureType) => {
            return {
                value: capture.id,
                label: capture.name,
            };
        });
    }, [captures]);

    useEffect(() => {
        if (isAdmin) {
            ApiGetCompanies(user.token).then(response => setCompanies(response));
        }
    }, [isAdmin]);

    useEffect(() => {
        if (user.token) {
            ApiGetCaptures(user.token).then(response => setCaptures(response));
        }
    }, []);

    return (
        <Container>
            <Title>Add new project</Title>
            <FormContainer>
                {!!error && <ErrorBanner>{error}</ErrorBanner>}
                <FormBlock>
                    <InputLabel htmlFor="name">Name:</InputLabel>
                    <InputText
                        name="name"
                        value={name}
                        onChange={event => setName(event.currentTarget.value)}
                        autoComplete="off"
                        onBlur={() => null}
                    />
                </FormBlock>
                <FormBlock>
                    <InputLabel htmlFor="location">Location:</InputLabel>
                    <InputText
                        name="location"
                        value={location}
                        onChange={event => setLocation(event.currentTarget.value)}
                        autoComplete="off"
                        onBlur={() => null}
                    />
                </FormBlock>
            </FormContainer>
            <SelectContainer>
                {!!isAdmin && !!formattedCompanies.length && (
                    <CustomSelect
                        value={selectedCompany}
                        onChange={event => setSelectedCompany(event.currentTarget.value)}
                        placeholder="Select company"
                    >
                        <option value="">Select company</option>
                        {formattedCompanies.map(company => (
                            <option key={`${company.value}-${company.label}`} value={company.value}>
                                {company.label}
                            </option>
                        ))}
                    </CustomSelect>
                )}
                <CustomMultiSelect>
                    {!!formattedCaptures.length && (
                        <MultiSelect
                            options={formattedCaptures}
                            value={selectedCaptures}
                            onChange={setSelectedCaptures}
                            labelledBy=""
                        />
                    )}
                </CustomMultiSelect>
            </SelectContainer>
            <StyledButton onClick={() => addProjectCallback()}>Add project</StyledButton>
        </Container>
    );
};

export default AddProject;
