import React from "react";

import CaptureStart from "../components/capture/CaptureStart";
import CaptureRunning from "../components/capture/CaptureRunning";
import CaptureStop from "../components/capture/CaptureStop";

import { GetUserFromCookie } from "../functions/Cookie";
import { connect } from "react-redux";

type ActionType = {
  type: string;
  payload: string;
};

type PropsType = {
  url: string;
  captureState: string;
  dispatch: (arg: ActionType) => void;
};

const Capture = (props: PropsType) => {
  const renderRedirect = () => {
    if (GetUserFromCookie() === undefined) {
      if (typeof window !== "undefined")
        return (window.location.href = "/login");
    }
  };

  const changeCaptureState = (value: string) => {
    props.dispatch({ type: "SET_CAPTURESTATE", payload: value });
  };

  const Page = () => {
    switch (props.captureState) {
      case "CAPTURE_START":
        return (
          <>
            <CaptureStart />
          </>
        );
      case "CAPTURE_RUNNING":
        return (
          <>
            <CaptureRunning />
          </>
        );
      case "CAPTURE_STOP":
        return (
          <>
            <CaptureStop />
          </>
        );
      default:
        return (
          <>
            <CaptureStart />
          </>
        );
    }
  };

  return (
    <>
      {renderRedirect()}
      {Page()}
    </>
  );
};

function mapStateToProps(state: PropsType) {
  return {
    captureState: state.captureState
  };
}

export default connect(mapStateToProps)(Capture);
