import axios from 'axios';

export const ApiGetLoadMoreCapturesFromProject = (projectId: number, amount: number, token: string) => {
    const requestUrl =
        window.location.hostname === 'localhost'
            ? `/api/projects/${projectId}/captures/${amount}`
            : window.location.origin.replace('://', '://api.') + `/api/projects/${projectId}/captures/${amount}`;
    var config = {
        headers: { Authorization: 'Bearer ' + token },
    };
    try {
        const response = axios.get(requestUrl, config).then(res => {
            if (res.status === 200) {
                return res.data;
            }
        });
        return response;
    } catch (error) {
        console.error('Error fetching data.');
    }
};
