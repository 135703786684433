import { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialStoreStateType } from '../../redux/reducers';
import { ApiAdminCheck } from '../../calls/ApiAdminCheck';
import { response } from 'express';

const useScreen = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const user = useSelector((storeState: InitialStoreStateType) => storeState.user);

    useEffect(() => {
        if (typeof user === 'undefined') {
            if (typeof window !== 'undefined') window.location.href = '/login';
        }
    }, [user]);

    useEffect(() => {
        if (typeof user !== 'undefined') {
            ApiAdminCheck(user.token).then(response => setIsAdmin(response));
        }
    }, []);

    return {
        user,
        isAdmin,
    };
};

export default useScreen;
