import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CircularProgressbar from 'react-circular-progressbar';

import store from '../../redux/store';

const isWindow = typeof window !== 'undefined';

const ProgressBar = styled(CircularProgressbar)`
    width: 68px;
    top: 18px;
    left: 21.5px;
    z-index: 0;
    position: absolute;
`;

const CameraInp = styled.div`
    position: relative;
    display: inline-block;

    & input {
        display: none;
    }

    & span {
        visibility: hidden;
        text-align: center;
        width: 220px;
        background-color: #26292b;
        color: #ffc069;
        // border: 1px solid #fa8c2a;
        border-radius: 20px;
        border-bottom-left-radius: 0px;
        padding-top: 11px;
        position: absolute;
        z-index: 1;
        left: 50px;
        top: -15px;
        height: 26px;
    }

    &:hover span {
        visibility: visible;
    }
`;

const Label = styled.label`
    display: block;
    width: 90px;
    padding-top: 85px;
    cursor: pointer;
    background: ${props =>
        // @ts-ignore
        props.background ? "url('../../assets/images/Upload.svg')" : "url('../../assets/images/Camera.svg')"};
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: center;
    text-align: center;
    color: #ffc069;
    margin-left: 11px;
    z-index: 0;
    position: relative;
`;

type PropsType = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

const Camera = (props: PropsType) => {
    const [progress, setProgress] = useState<number>(50);
    useEffect(() => {
        const unsubscribe = store.subscribe(() => onStoreChange());
        const data = store.getState().imageUploadProgress;
        console.log(data);
        setProgress(data);
        data === 100 && setTimeout(() => setProgress(0), 7000);

        return unsubscribe;
    }, [store.getState().imageUploadProgress]);

    const onStoreChange = async () => {
        setProgress(store.getState().imageUploadProgress);
    };

    const isMobileDevice = () => {
        return (
            (!!window && typeof window.orientation !== 'undefined') ||
            (!!window &&
                !!window.navigator &&
                typeof window.navigator !== 'undefined' &&
                window.navigator.userAgent.indexOf('IEMobile') !== -1)
        );
    };

    return (
        <CameraInp>
            <input type="file" name="file" id="file" onChange={props.onChange} accept="image/*" />
            <ProgressBar
                percentage={progress}
                background
                strokeWidth={5}
                styles={{
                    background: {
                        fill: '#26292B',
                    },
                    // Customize the path, i.e. the part that's "complete"
                    path: {
                        // Tweak path color:
                        stroke: '#FA8C2A',
                        // Tweak path to use flat or rounded ends:
                        strokeLinecap: 'butt',
                        // Tweak transition animation:
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                    },
                    // Customize the circle behind the path
                    trail: {
                        // Tweak the trail color:
                        stroke: '#26292B',
                    },
                    // Customize the text
                    text: {
                        // Tweak text color:
                        fill: '#FA8C2A',
                        // Tweak text size:
                        fontSize: '25px',
                    },
                }}
            />
            <span>Upload one image at a time</span>
            {/* @ts-ignore */}
            <Label background={isMobileDevice} htmlFor="file">
                {progress === 100 ? 'Uploaded! ' : 'Add photo'}
            </Label>
        </CameraInp>
    );
};
export default Camera;
