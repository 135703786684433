import React, { useState, useEffect } from "react";
import styled from "styled-components";

import store from "../../redux/store";
import { ApiGetAvailableDiskSpace } from "../../calls/ApiGetAvailableDiskSpace";
import { setDiskspace } from "../../redux/actions/index";

const DiskspaceImage = styled.div`
  background: url("../assets/images/disk.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  display: block;
  margin: 0 auto;

  &: hover p {
    display: block;
    position: absolute;
    width: 100px;
    background: #26292b;
    top: 30px;
    right: 15px;
    color: #ffc069;
    padding: 10px 20px;
    border-radius: 20px;
    border-top-right-radius: 0px;
    border: 1px solid #fa8c2a;
    z-index: 3;
  }
`;

const DiskspaceInfo = styled.p`
  display: none;
`;

const Diskspace = () => {
  const [space, setSpace] = useState("");

  useEffect(() => {
    (async () => {
      const unsubscribe = store.subscribe(() => onStoreChange());
      const diskspace = await ApiGetAvailableDiskSpace();
      store.dispatch(setDiskspace(diskspace));

      return unsubscribe;
    })();
  }, []);

  const onStoreChange = () => {
    const diskspace = store.getState().diskspace;
    setSpace(diskspace);
  };

  return (
    <DiskspaceImage>
      <DiskspaceInfo>{space} GB available</DiskspaceInfo>
    </DiskspaceImage>
  );
};

export default Diskspace;
