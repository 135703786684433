import axios from "axios";

export const ApiPutCaptureComment = async (
  id: number,
  token: string,
  comment: string
) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/comment/" + id : window.location.origin.replace("://", "://api.") + "/api/captures/comment/" + id;
  const config = {
    headers: { Authorization: "Bearer " + token }
  };
  const postbody = { Comment: comment };
  try {
    const response = await axios.put(requestUrl, postbody, config);
    return response.data;
  } catch {
    console.error(`Cannot add/edit comment for capture ${id}`);
    return null;
  }
};
