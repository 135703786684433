import axios from 'axios';

import { setImageUploadProgress } from '../redux/actions';
import store from '../redux/store';

export const ApiPostPhoto = async (name: string, token: string, file: File) => {
    const requestUrl =
        window.location.hostname === 'localhost'
            ? `/api/photos/${name}`
            : window.location.origin.replace('://', '://api.') + `/api/photos/${name}`;
    const config = {
        headers: { Authorization: 'Bearer ' + token },
        onUploadProgress: (progressEvent: ProgressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            store.dispatch(setImageUploadProgress(percentCompleted));
        },
    };

    try {
        const formData = new FormData();
        formData.append('file', file);
        await axios.post(requestUrl, formData, config);
    } catch (error) {
        alert('Error while uploading image');
    }
};
