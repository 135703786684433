import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import LoadingAnimation from './common/LoadingAnimation';

const THREE = require('three');
const PLYLoader = require('three-ply-loader');
const OrbitControls = require('../assets/lib/OrbitControls');
PLYLoader(THREE);

const Container = styled.div`
    display: flex;
    justify-content: center;
`;
const ThreeD = styled.div`
    width: 95vw;
    height: 95vh;
`;
const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    top: calc(50vh - 150px);
    position: absolute;
`;

const Viewer = (props: { path: string }) => {
    const [loading, setLoading] = useState(true);
    let mount = useRef<HTMLDivElement>(null);
    let controls: typeof OrbitControls;
    let renderer: typeof THREE.WebGLRenderer;
    let scene: typeof THREE.Scene;
    let camera: typeof THREE.PerspectiveCamera;

    useEffect(() => {
        camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 15);
        const width = mount.current?.clientWidth;
        const height = mount.current?.clientHeight;

        scene = new THREE.Scene();
        scene.background = new THREE.Color(0x383e40);

        // camera.position.set(3, -0.15, 7);
        camera.position.set(3, 0.75, 8);

        let cameraTarget = new THREE.Vector3(0, -3, 0);
        camera.lookAt(cameraTarget);

        renderer = new THREE.WebGLRenderer({ antialias: true });
        controls = new OrbitControls(camera, renderer.domElement);

        renderer.setSize(width, height);
        mount.current?.appendChild(renderer.domElement);
        initializeOrbits();
        let loader = new THREE.PLYLoader();
        let group = new THREE.Object3D();

        loader.load(
            window.location.hostname === 'localhost'
                ? '/api/files/getquickply/' + props.path
                : window.location.origin.replace('://', '://api.') + '/api/files/getquickply/' + props.path,
            function(geometry: string) {
                let material = new THREE.PointsMaterial({
                    size: 0.03,
                    opacity: 1,
                    vertexColors: THREE.VertexColors,
                });
                group = new THREE.Points(geometry, material);
                group.sortParticles = true;
                group.scale.multiplyScalar(0.5);
                group.position.y = 0.42;
                group.rotation.y = Math.PI;
                // group.rotation.y = 0.62;
                group.rotation.x = Math.PI / 2;
                group.castShadow = true;
                group.receiveShadow = true;
                scene.add(group);
                setLoading(false);
            },
        );
        animate();
    }, []);

    const animate = () => {
        const frameId = window.requestAnimationFrame(animate);
        renderer.render(scene, camera);
    };

    const initializeOrbits = () => {
        controls.rotateSpeed = 1.0;
        controls.zoomSpeed = 2;
        controls.panSpeed = 0.2;
    };

    return (
        <Container>
            {loading && (
                <LoadingContainer>
                    <LoadingAnimation margin="auto" />
                </LoadingContainer>
            )}
            <ThreeD ref={mount}></ThreeD>
        </Container>
    );
};
export default Viewer;
