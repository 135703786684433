import React from "react";
import Iframe from "react-iframe";

export default React.memo(({ imagePath }: { imagePath: string }) => {
  // const url = `https://${
  //   typeof window !== "undefined" &&
  //   window.location.hostname === "sewermapper.eu"
  //     ? "vr.sewermapper.eu"
  //     : "vr.sewermapper.local"
  // }/?name=${imagePath}`;
  const url = `https://vr.sewermapper.eu/?name=${imagePath}`;

  return (
    <Iframe
      url={url}
      width="99%"
      height="99%"
      id="threesixty"
      className="threesixty"
      //@ts-ignore
      display="initial"
      position="relative"
    />
  );
});
