import React, { useState, useEffect } from "react";
import styled from "styled-components";

import store from "../redux/store";
import { GetProcessingProgress } from "../calls/GetProcessingProgress";
import { setProgress } from "../redux/actions/index";
import ApiPutCaptureProcess from "../calls/ApiPutCaptureProcess";
import StartProcess from "../calls/StartProcess";

const Container = styled.div`
  text-align: center;
`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 3%;
  transition-duration: 0.4s;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }
`;

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

type PropsType = {
  name: string;
  status: string;
  id: number;
  fullname: string;
  token: string;
};

const Process = (props: PropsType) => {
  const [user, setUser] = useState({} as UserType);
  const [directory, setDirectory] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());
    const GetData = async () => {
      const currentUser = await store.getState().user;
      await setUser(currentUser);
      props.name && setDirectory(props.name);
      // @ts-ignore
      window && (window.store = store);
      // @ts-ignore
      window && (window.setProgress = setProgress);
    };
    GetData();

    return unsubscribe;
  }, []);

  const onStoreChange = async () => {
    const currentUser = await store.getState().user;
    setUser(currentUser);
  };

  const processProgress = async () => {
    await GetProcessingProgress();
    store.dispatch(setProgress(100));
    setStatus("Processed succesfully");
  };

  const onAction = (quick: number) => {
    StartProcess(directory, quick);
    if (!quick) {
      ApiPutCaptureProcess(props.id, props.fullname, props.token);
    }
    try {
      processProgress();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Container>
      {(!user || props.status === "Preview" || user?.id === 1) && (
        <Button onClick={() => onAction(0)}>Full Process</Button>
      )}
      {user?.id === 1 && <Button onClick={() => onAction(1)}>Quick Process</Button>}
    </Container>
  );
};

export default Process;
