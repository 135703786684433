import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: ${(props: { isInside?: boolean }) => (props.isInside ? 'relative' : 'absolute')};
    top: 50%;
    left: ${(props: { isInside?: boolean }) => (props.isInside ? 0 : '50%')};
`;
const SpinnerStyle = styled.div`
    width: 26px;
    height: 26px;
    animation: spin 1s infinite linear;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
const Mask = styled.div`
    width: 12px;
    height: 12px;
    overflow: hidden;
`;

const MaskedCirlce = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 12px;
    border: 3px solid ${(props: { spinnerColor?: string }) => (props.spinnerColor ? props.spinnerColor : '#fa8c2a')};
`;
const Spinner = (props: { spinnerColor?: string; isInside?: boolean }) => {
    return (
        <Container isInside={props.isInside}>
            <SpinnerStyle>
                <Mask>
                    <MaskedCirlce spinnerColor={props.spinnerColor} />
                </Mask>
            </SpinnerStyle>
        </Container>
    );
};

export default Spinner;
