import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Toggle from "./common/Toggle";
import Diskspace from "./common/Diskspace";

import store from "../redux/store";
import { EmptyUserCookie } from "../functions/Cookie";
import { setUser, setHidden } from "../redux/actions/index";

const TopBarOverlay = styled.div`
  position: fixed;
  background: #26292b;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border: 3px solid #fa8c2a;
  color: #ffc069;
  text-align: right;
  padding: 5px 15px;
  right: 33px;
  top: 75px;
  z-index: 2;

  a {
    color: white;
    text-decoration: none;
    margin: 10px 0;
    display: block;
    text-align: center;
    &:hover {
    text-decoration: underline;
  }
  }
`;

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

const UserOverlay = () => {
  const [user, setCurrentUser] = useState<UserType | null>(null);
  const [hidden, setOverlayHidden] = useState<boolean | null>(null);
  const [screen, setScreenMode] = useState("");
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());
    toggleHidden();
    changeUser();

    return unsubscribe;
  }, []);

  const onStoreChange = () => {
    changeUser();
    toggleHidden();
    screenMode();
  };

  const changeUser = () => {
    const currentUser = store.getState().user;
    if (user !== currentUser) setCurrentUser(currentUser);
  };
  const toggleHidden = () => {
    const data = store.getState().navOverlayHidden;
    if (hidden !== data) setOverlayHidden(data);
  };

  const screenMode = () => {
    const data = store.getState().screenMode;
    if (screen !== data) setScreenMode(data);
  };

  const logout = () => {
    if (user !== null) {
      store.dispatch(setUser(null));
      store.dispatch(setHidden(true));
      EmptyUserCookie();
      setUser(null);
      setError(null);
      window.location.href = window.location.origin + "/login";
    } else {
      if (error === null) setError("User already logged out");
    }
    if (window) window.location.reload();
  };

  return (
    <>
      {!hidden && user != null && (
        <TopBarOverlay>
          <p>You are logged in as {user.username}</p>
          {/* <Diskspace />  // diskspace is hidden because it has no use on cloud*/}
          <a href="/login" onClick={logout}>
            Log out
          </a>
          <Toggle class="darkmode" items={["Light", "Dark"]} active="Dark" />
        </TopBarOverlay>
      )}
    </>
  );
};

export default UserOverlay;
