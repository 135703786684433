import React, { useState } from "react";
import styled from "styled-components";

import store from "../../redux/store";
import { setScreenMode } from "../../redux/actions/index";

const ToggleButton = styled.div`
  border: 1px solid #fa8c2a;
  border-radius: 34px;
  width: fit-content;
  background: #26292b;
  margin: auto;
`;

const Item = styled.li`
  padding: 10px;
  display: inline-block;
  padding: 10px 15px;
  border-left: 1px solid #fa8c2a;
  color: #ffc069;
  width: fit-content;

  &:first-child {
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
  }
  &:last-child {
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
  }

  @media screen and (max-width: 375px) {
    font-size: smaller;
  }
`;

const ActiveItem = styled(Item)`
  color: white;
  background-color: #fa8c2a;
`;

type Props = {
  items: string[];
  active: string;
  values?: string[];
  class?: string;
  callback?: (value: string) => void;
};

const Toggle = (props: Props) => {
  const [active, setActive] = useState(props.active);

  const items = props.items.map((item: string, index: number) => {
    if (props.values) {
      if (item === active) {
        return (
          <ActiveItem
            key={item}
            onClick={() => {
              clickHandler(item, props.values![index]);
            }}
          >
            {item}
          </ActiveItem>
        );
      } else {
        return (
          <Item
            key={item}
            onClick={() => {
              clickHandler(item, props.values![index]);
            }}
          >
            {item}
          </Item>
        );
      }
    } else {
      if (item === active) {
        return (
          <ActiveItem
            key={item}
            onClick={() => {
              clickHandler(item);
            }}
          >
            {item}
          </ActiveItem>
        );
      } else {
        return (
          <Item
            key={item}
            onClick={() => {
              clickHandler(item);
            }}
          >
            {item}
          </Item>
        );
      }
    }
  });

  const clickHandler = async (item: string, value?: string) => {
    await setActive(item);
    if (value) {
      props.callback!(value);
    }
    if (props.class === "darkmode") {
      store.dispatch(setScreenMode(item));
    }
  };

  return <ToggleButton>{items}</ToggleButton>;
};

export default Toggle
