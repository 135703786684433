import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import styled from 'styled-components'
import "./App.css";

import Login from "./screens/Login";
import Capture from "./screens/Capture";
import History from "./screens/History";
import ThreeDScreen from "./screens/ThreeDScreen";
import Help from "./screens/Help";
import ErrorScreen from "./screens/ErrorScreen";
import AddProject from "./screens/AddProject";

import TopBar from "./components/TopBar";

import store from "./redux/store";
import { setHidden, setScreenMode, setUser } from "./redux/actions/index";
import { GetUserFromCookie } from "./functions/Cookie";


const Body = styled.div`
  background-color: #383e40;
`

const App = (props: any) => {
  const [screen, setScreen] = useState("");
  const [user, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());

    const Initialise = async () => {
      const userData = GetUserFromCookie();
      store.dispatch(setUser(userData));
      setCurrentUser(userData);
    };
    Initialise();
    store.dispatch(setHidden(true));
    store.dispatch(setScreenMode("Dark"));
    window && window.scrollTo(0, 0);

    return unsubscribe;
  }, [store]);

  const changeBody = () => {
    const data = store.getState().screenMode;
    if (screen !== data) setScreen(data);
    if (window.document)
      window.document.body.className = store.getState().screenMode;
  };

  const onStoreChange = () => {
    changeBody();
  };

  // Self-made router
  const getComponent = () => {
    store.subscribe(() => null);
    if (props.url.includes("/preview")) {
      return <ThreeDScreen captureId={props.captureId} {...props} />;
    }
    if (props.url.includes("/project/add")) {
      return <AddProject/>
    }
    if (props.url.includes("/project")) {
      return <History projectId={props.url.split("/").pop()} {...props} />
    }
    switch (props.url) {
      case "/":
        return <History {...props} />;
      case "/login":
        return <Login {...props} />;
      case "/capture":
        return <Capture {...props} />;
      case "/help":
        return <Help {...props} />;
      default:
        return <ErrorScreen {...props} />;
    }
  };
  return (
    <Provider store={store}>
      <Body>
        <TopBar />
        {getComponent()}
      </Body>
    </Provider>
  );
};

export default App;
