import React, { useState, useEffect } from "react";
import styled from "styled-components";

import checkmark from "../../assets/images/checkmark.svg";
import circle from "../../assets/images/circle.svg";

const Popup = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #383e40;
  top: 0;
  z-index: 100;
`;

const Animation = styled.div`
  width: 200px;
  margin: auto;
  margin-top: 30vh;
`;

const Image = styled.img`
  display: block;
  width: 120px;
  margin: auto;
  margin-top: -139px;
  animation: grow 1s;

  @keyframes grow {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Text = styled.h4`
  margin-top: 11vh;
`;

const LoginAnimation = () => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 1700);

    return ()=>{clearTimeout(timer)}
  }, []);

  const renderRedirect = () => {
    if (redirect) {
      if (typeof window !== "undefined") window.location.href = "/";
    }
  };

  return (
    <Popup>
      <Animation>
        <img src={circle} alt="circle" />
        <Image src={checkmark} alt="checkmark" />
        <Text>Successfuly logged in!</Text>
        {renderRedirect()}
      </Animation>
    </Popup>
  );
};

export default LoginAnimation
