import React, { useState, useEffect, SetStateAction } from "react";
import styled from "styled-components";
const isWindow = typeof window !== "undefined";

import Process from "../components/Process";
import MeasurementsViewer from "../components/MeasurementsViewer";
import { default as Sewermapper360 } from "../components/360Viewer";
import Overlay3DViewer from "../components/Overlay3DViewer";
import VerticalCarousel from "../components/VerticalCarousel";
import ImageUploadSection from "../components/ImageUploadSection";

import store from "../redux/store";
import { ApiGetUserInfo } from "../calls/ApiGetUserInfo";
import { ApiGetSingleCapture } from "../calls/ApiGetSingleCapture";
import { GetFisheyePaths } from "../calls/GetFisheyePaths";
import { GetUserFromCookie } from "../functions/Cookie";
import CaptureComment from "../components/CaptureComment";
import BackButton from "../components/common/BackButton";

const Container = styled.div`
  margin-top: 15vh;
`;

const InformationHeader = styled.div`
  text-align: center;
  color: #fa8c2a;

  & h2 {
    margin-block-end: 0;
  }

  p {
    margin-block-end: 0em;
    margin-block-start: 0em;
    font-weight: bold;
  }
  p:nth-child(3) {
    margin-block-end: 1em;
    font-weight: normal;
  }
`;

const InformationDetails = styled.div`
  width: fit-content;
  margin: auto;
`;

const ModelBlock = styled.div`
  width: calc(100% - 40px);
  padding: 20px 20px;
  text-align: center;

  & p {
    color: #fa8c2a;
    font-size: 15px;
  }

  @media screen and (min-width: 700px) {
    width: calc(100% - 80px);
    padding: 20px 40px;
  }

  @media screen and (min-width: 1000px) {
    width: calc(50% - 50px);
    margin-left: 10px;
    padding: 20px;
    display: inline-block;
  }
`;
const ModelView = styled.div`
  border: 1.5px solid #fa8c2a;
  border-radius: 0;
  height: 45vh;
  position: relative;
`;

const Status = styled.div`
  background-color: ${props =>
    props.color === "Processed" ? "#00C66B" : "#F98B2A"};
  color: white;
  width: 100px;
  margin: auto;
  text-align: center;
  margin-top: 5px;
`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 3%;
  transition-duration: 0.4s;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }
`;

const DownloadButton = styled.a`
  float: right;
  display: block;
  width: 90px;
  padding-top: 85px;
  cursor: pointer;
  background: url("../assets/images/Download.svg");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: center;
  text-align: center;
  color: #ffc069;
  margin-top: -100px;

  @media screen and (max-width: 460px) {
    width: 67px;
    background-size: 46px;
  }
`;

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

type CaptureType = {
  key: number;
  id: number;
  dateTime: string | Date;
  deviceName: string;
  location: string;
  syncStatus: string;
  processStatus: string;
  fullName: string;
  userId: number;
  path: string;
  comment: string;
};

type CaptureUserType = {
  id: number;
  companyId: number;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  companyName: string;
};

type DataHookType = [
  boolean,
  UserType,
  CaptureType,
  string[],
  CaptureUserType,
  string,
  React.Dispatch<SetStateAction<UserType>>,
  React.Dispatch<SetStateAction<string>>
];

const ThreeDScreen = () => {
  const [
    loading,
    user,
    capture,
    images,
    captureUser,
    selectedImage,
    setUser,
    setSelectedImage
  ] = useData();

  const [overlay, setOverlay] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());
    isWindow && window.scrollTo(0, 0);

    return unsubscribe;
  }, []);

  const onStoreChange = async () => {
    const currentUser = store.getState().user;
    setUser(currentUser);
  };

  const openNav = () => {
    setOverlay(true);
    setShowButton(true);
  };

  const closeNav = (overlayState: boolean, buttonState: boolean) => {
    setOverlay(overlayState);
    setShowButton(buttonState);
  };

  const handleImageSelect = (imagePath: string) => {
    setSelectedImage(imagePath);
  };

  const renderRedirect = () => {
    if (user === undefined) {
      if (isWindow) window.location.href = "/login";
    }
  };

  return (
    <>
      {renderRedirect()}
      <Container>
        {!loading && (
          <>
            {overlay && (
              <Overlay3DViewer
                closeNav={closeNav}
                data={capture}
                showOverlay={overlay}
                showButton={showButton}
              />
            )}
            <InformationHeader>
              <BackButton onClick={() => {if (window) window.history.back()}} appendText="back to captures" />
              <InformationDetails>
                <h2>Manhole ID: {capture?.fullName}</h2>
                <p>{capture?.dateTime?.toString()}</p>
                <p>User: {captureUser?.username}</p>
                <p>Company: {captureUser?.companyName}</p>
                <Status color={capture?.processStatus}>
                  {capture?.processStatus}
                </Status>
              </InformationDetails>
              {/* <DownloadButton
                href={`/api/scanner/download/${capture.fullName}`}
              /> */}
            </InformationHeader>

            <ModelBlock>
              <p>360°</p>
              <ModelView>
                {images && images.length > 0 && (
                  <VerticalCarousel
                    images={images}
                    onSelectImage={handleImageSelect}
                  />
                )}
                <Sewermapper360 imagePath={selectedImage} />
              </ModelView>
              {isWindow && (
                <a
                  href={`https://vr.sewermapper.eu/?name=${capture.fullName}`}
                  target="_blank"
                >
                  <Button>Full Screen</Button>
                </a>
              )}
            </ModelBlock>

            <MeasurementsViewer
              event={openNav}
              path={
                window.location.hostname === "localhost"
                  ? `/api/files/getfile/ManholePNGsource/${capture.fullName}_report.png`
                  : window.location.origin.replace("://", "://api.") +
                    `/api/files/getfile/ManholePNGsource/${capture.fullName}_report.png`
              }
            />
            <CaptureComment
              id={capture.id}
              token={user.token}
              comment={capture.comment}
            ></CaptureComment>
            <ImageUploadSection capture={capture} />
            <Process
              name={capture?.path}
              status={capture?.processStatus}
              id={capture?.id}
              fullname={capture?.fullName}
              token={user.token}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default ThreeDScreen;

const useData = (): DataHookType => {
  const [user, setUser] = useState({} as UserType);
  const [capture, setCapture] = useState({} as CaptureType);
  const [images, setImages] = useState<string[]>([]);
  const [captureUser, setCaptureUser] = useState({} as CaptureUserType);
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const currentUser = GetUserFromCookie();
      setUser(currentUser);
      const captureData = await getCapture(currentUser.token);
      setCapture(captureData!);
      const captureUserData = await ApiGetUserInfo(
        captureData!.userId,
        currentUser.token
      );
      setCaptureUser(captureUserData);
      try {
        const captureImages = await getImages(captureData!.path);
        setImages(captureImages!);
        setSelectedImage(captureImages![0]);
      } catch {
        console.error("Error fetching Images");
      }
      setLoading(false);
    };
    getData();
  }, [store.getState().user]);

  return [
    loading,
    user,
    capture,
    images,
    captureUser,
    selectedImage,
    setUser,
    setSelectedImage
  ];
};

const getCapture = async (userToken: string) => {
  const id = isWindow && window.location.href.split("/").pop();
  if (id && userToken) {
    const response = await ApiGetSingleCapture(userToken, id);
    if (response) {
      const status = response.status ? "Processed" : "Preview";
      const date = Date.parse(response.timeStamp);
      const capture = {
        key: response.id,
        id: response.id,
        dateTime: date
          ? new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            }).format(date)
          : new Date(),
        deviceName: "Prototype-1",
        location: response.location,
        syncStatus: status,
        processStatus: status,
        fullName: response.name,
        userId: response.userId,
        path: response.path,
        comment: response.comment
      };
      return capture;
    } else {
      window.location.href = "/error";
    }
  }
};

const getImages = async (capturePath: string) => {
  if (capturePath) {
    let images: string[] = await GetFisheyePaths(capturePath);
    return images;
  }
};
