import axios from 'axios';

export type CompanyType = {
    id: string;
    name: string;
    streetAndNumber: string;
    postalCode: string;
    place: string;
    email: string;
    phoneNumber: string;
    vatNumber: string;
    companyTypeId: number;
    sybscriptionId: number;
    createdAt: Date;
};

export const ApiGetCompanies = async (token: string): Promise<CompanyType[]> => {
    const requestUrl =
        window.location.hostname === 'localhost'
            ? '/api/companies'
            : window.location.origin.replace('://', '://api.') + '/api/companies';
    var config = {
        headers: { Authorization: 'Bearer ' + token },
    };

    try {
        return await (await axios.get(requestUrl, config)).data;
    } catch (error) {
        return [];
    }
};
