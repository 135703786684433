import React from "react";
import styled, { css } from "styled-components";

import HelpBlock from "../components/HelpBlock";

const Container = styled.div`
  margin-top: 13vh;
`;

const Help = () => {
  return (
    <Container>
      <HelpBlock
        title="Forgotten your username?"
        text={
          "If you've forgotten your username, contact your local administrator. He has access to all usernames, and can help you retrieve it."
        }
      />
      <HelpBlock
        title="Forgotten your password?"
        text={
          "If you've forgotten your password, you can always send a mail to <a href='mailto:forgotten@sewermapper.net'>forgotten@sewermapper.net</a>.<br>We then will reset your password and send you an e-mail with a newly generated password.<br>We are probably going to automate this process, but you'll hear when it's done."
        }
      />
      <HelpBlock
        title="Not sure how to use this application?"
        text={
          "If you're not sure how to use this application, then don't. This application requiers a minimum of skill and people who are permitted to use this application normally have educated to work with it."
        }
      />
    </Container>
  );
};

export default Help;
