import React from 'react';
import styled from 'styled-components';

import time from '../../assets/images/time.svg';
import location from '../../assets/images/location.svg';

import { ProjectType } from './History.type';
import { formatDate } from '../../functions/utils';

const CardGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    grid-auto-rows: auto;
    text-align: center;
`;

const Card = styled.a`
    border: 2px solid #ffa94d;
    border-radius: 5px;
    background-color: #fa8c2a;
    padding: 40px 20px 20px;
    color: #d9480f;
    transition: 0.3s;
    text-decoration: none;
    position: relative;
    &:hover {
        transform: scale(1.02);
        box-shadow: 0 10px 20px rgba(37, 33, 82, 0.12), 0 4px 8px rgba(37, 33, 82, 0.06);
    }
`;

const List = styled.ul`
    list-style: none;
    color: white;
    padding-inline-start: 0px;
    font-weight: normal;
`;

const ListTitle = styled.h3`
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 0;
    span {
        font-weight: bold;
    }
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    margin: 15px 0px;
    padding-bottom: 10px;

    & span {
        margin: auto;
    }

    &&:nth-child(1) {
        font-weight: bold;
        color: white;
        border-bottom: none;
    }

    &:nth-child(4) {
        border-bottom: none;
        padding-bottom: 5px;
    }
`;

export default React.memo((props: { projects: ProjectType[]; search: string }) => {
    const { projects, search } = props;

    return (
        <CardGroup>
            {projects.map(project => {
                if (
                    project.name.toUpperCase().indexOf(search.toUpperCase()) === -1 ||
                    (project.id === 0 && project.capturesCount === 0)
                )
                    return null;
                return (
                    <Card
                        key={`${project.id}-${project.name}`}
                        href={`/project/${project.id}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <List>
                            <ListTitle>
                                Project : <span>{project.name}</span>
                            </ListTitle>
                            <ListItem>
                                <img src={time} alt="time" width="20px" height="20px" />
                                <span>{!project.timeCreated.toString().includes('0001-01-01') ? project.timeCreated.toString().slice(0, 10) : 'N/A'}</span>
                            </ListItem>
                            <ListItem>
                                <img src={location} alt="location" width="20px" height="20px" />
                                <span>{project.location}</span>
                            </ListItem>
                            <ListItem>
                                <span>{project.capturesCount} captures available</span>
                            </ListItem>
                        </List>
                    </Card>
                );
            })}
        </CardGroup>
    );
});
