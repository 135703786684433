import React, { useState } from 'react';
import styled from 'styled-components';

import OverlayHistoryFilter from '../components/historycomponents/OverlayHistoryFilter';
import SyncForm from '../components/historycomponents/SyncForm';
import useScreen from './hooks/useScreen';

const HistoryContainer = styled.div`
    margin-top: 12vh;
`;

const HistoryTitle = styled.h2`
    margin-top: 40px;
    color: #fa8c2a;
    text-align: center;
`;

const AddProjectsLink = styled.a`
    margin-top: 10px;
    color: #fa8c2a;
    text-align: center;
    width: 100%;
    font-size: 18px;
    &:hover {
        color: #fff;
    }
`;

const History = (props: { projectId: number, projectName: string }) => {
    const { projectId, projectName} = props;

    const { user, isAdmin } = useScreen();

    return (
        <HistoryContainer>
            <HistoryTitle>
              {projectName && (
                <>
                  {projectName}
                  <br/>
                </>
              )}
                {projectId ? 'Captures' : 'Projects'}
                <br />
                {!projectId && <AddProjectsLink href="project/add">Add projects</AddProjectsLink>}
                {isAdmin && <SyncForm token={user.token} />}
            </HistoryTitle>
            {user && <OverlayHistoryFilter projectId={projectId} />}
        </HistoryContainer>
    );
};

export default History;
