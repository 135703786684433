import axios from "axios";
import { SetStateAction } from "react";

type MakeCaptureStopType = {
  setMachineState: React.Dispatch<SetStateAction<string>>;
}

export const MakeCaptureStop = async (event:MakeCaptureStopType) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/stop" : window.location.origin.replace("://", "://api.")+"/api/captures/stop";
  const response = await axios.get(requestUrl);
  if (response.data) event.setMachineState("Capture stopped");
};