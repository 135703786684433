import axios from "axios";
import { SetStateAction } from "react";

import { ApiPostCapture } from "./ApiPostCapture";

type ActionType = {
  type:string;
  payload:string;
}

type PropsType = {
  captureState: string;
  dispatch:(arg:ActionType) => void;
}

type MakeCaptureStartType = {
  name: string;
  setStartButton: React.Dispatch<SetStateAction<string>>;
  setMachineState: React.Dispatch<SetStateAction<string | null>>;
  setCapture: React.Dispatch<SetStateAction<string>>;
  props: PropsType;
}

export const MakeCaptureStart = async (event:MakeCaptureStartType, token:string) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/start" : window.location.origin.replace("://", "://api.")+"/api/captures/start";
  const config = {
    headers: { Authorization: "Bearer " + token }
  };
  const postVariables = {
    name: event.name
  };
  try {
    const response = await axios.post(requestUrl, postVariables, config);
    if (response.status === 200) {
      event.setStartButton("hidden");
      event.setMachineState("Please wait");
      setTimeout(() => {
        event.setMachineState("Calibrating devices");
        setTimeout(() => {
          event.setMachineState("Initialise sensor");
          setTimeout(() => {
            ApiPostCapture(event.name, 1, token, event);
            event.setMachineState("Running");
            event.props.dispatch({
              type: "SET_CAPTURESTATE",
              payload: "CAPTURE_RUNNING"
            });
          }, 5000);
        }, 5000);
      }, 5000);
    } 
    else event.setMachineState("Error, please reboot the system");
  } catch (error) {
    event.setMachineState(error);
  }
};
