import React, { useState } from "react";
import styled, { css } from "styled-components";

const Question = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 500px;
  margin: 20px auto;
  background: #26292b;
  padding: 10px 20px;
  border: 1px solid #fa8c2a;
  border-radius: 22px;
  color: #ffc069;

  & h1 {
    margin: 0 30px 0 0;
    font-size: 16px;
    color: #fa8c2a;
    font-weight: 100;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  right: 25px;
  top: 15px;
  background: url("../assets/images/Arrow.svg");
  transform: ${props => (props.active ? "rotate(0)" : "rotate(180deg)")};
  transition: transform 0.6s ease-in;
`;

const Text = styled.p`
  ${props =>
    props.active
      ? css`
          margin-top: 20px;
          margin-bottom: 10px;
          visibility: visible;
          height: auto;
          opacity: 1;
          max-height: 500px;
          transition: opacity 0.4s ease-in, max-height 0.5s ease-in,
            margin 0.4s ease-in;
        `
      : css`
          max-height: 0px;
          margin: 0;
          opacity: 0;
          transition: visibility 0.1s ease-out, 0.5s;
          visibility: hidden;
          transition: max-height 0.5s ease-in, margin 0.5s ease-in,
            opacity 0.4s ease-out;
        `}

  & a {
    color: #fff;
    text-decoration: none;
  }
`;

const HelpBlock = ({ title, text }: { title: string; text: string }) => {
  const [active, setActive] = useState(false);

  return (
    <Question onClick={() => {setActive(!active)}}>
      <h1>{title}</h1>
      {/* @ts-ignore */}
      <Arrow active={active} />
      {/* @ts-ignore */}
      <Text active={active} dangerouslySetInnerHTML={{ __html: text }} />
    </Question>
  );
};

export default HelpBlock;
