import axios from "axios";

export default async (token: string)  =>  {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/projects" : window.location.origin.replace("://", "://api.")+"/api/projects";
  var config = {
    headers: { Authorization: "Bearer " + token }
  };

  try {
    const response = await axios.get(requestUrl, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log("something went wrong fetching the data");
  }
};
