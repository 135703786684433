import React from 'react';
import styled from 'styled-components';
import defaultImage from '../../assets/images/logo.png';

import { HistoryType } from './History.type';

const Card = styled.div`
    transition: 0.3s;

    &:hover {
        transform: scale(1.05);
    }
`;

const Cardtitle = styled.p`
    padding-top: 1em;
    font-weight: bold;
    padding-left: 4em;
`;

const CardContent = styled.div`
    margin-left: 3%;
    background-color: #fa8c2a;
    color: white;
    min-height: 70px;
    border-radius: 10px;
`;
const CardData = styled.ul`
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    padding-inline-start: 0px;
    margin-top: 1em;
    padding-bottom: 0em;
    font-weight: normal;
`;
const CardDataItem = styled.li`
    @media all and (max-width: 480px) {
        font-size: small;
        border-right: inset;

        &:nth-child(3) {
            border-right: none;
        }
    }
`;

const Image = styled.img`
    border-radius: 50%;
    float: left;
    border: 1px solid #fa8c2a;
    width: 3.5em;
    height: 3.5em;
    background-color: #383e40;
`;

const Status = styled.div`
    background-color: ${props => (props.color === 'Processed' ? '#00C66B' : 'yellow')};
    color: white;
    width: 100px;
    text-align: center;
    float: right;
    margin-right: 2%;
`;

const HistoryBlockList = (props: { captures: HistoryType[]; search: string }) => {
    const captures = props.captures.map((capture: HistoryType) => {
        const status = capture.processStatus;
        if (capture.fullName.toUpperCase().indexOf(props.search.toUpperCase()) === -1) return null;
        return (
            <a key={capture.key} href={`/preview/${capture.id}`} style={{ textDecoration: 'none' }} className="block">
                <Card>
                    <Image
                        src={
                            !!capture.thumbnail
                                ? window.location.origin.replace('://', '://api.') + capture.thumbnail
                                : defaultImage
                        }
                    />
                    <CardContent>
                        <Status color={status}>{status}</Status>
                        <Cardtitle>Capture: {capture.fullName}</Cardtitle>
                        <CardData>
                            <CardDataItem>{capture.dateTime}</CardDataItem>
                            <CardDataItem>{capture.location}</CardDataItem>
                            <CardDataItem>{capture.deviceName}</CardDataItem>
                        </CardData>
                    </CardContent>
                </Card>
            </a>
        );
    });

    return <>{captures}</>;
};

export default HistoryBlockList;
