import React, { SetStateAction } from "react";
import Modal from "react-modal";
import styled from "styled-components";

const CloseButton = styled.button`
  position: absolute;
  right: -20px;
  top: -20px;
  border: 2px solid orange;
  color: orange;
  cursor: pointer;
  z-index: 10000000;
  border-radius: 30px;
  padding: 20px 12px;
  display: inline-block;
  line-height: 0px;
  font-weight: bolder;
  font-size: 24px;
  background-color: rgba(56, 62, 64, 0.97);
`;

const customStyles = {
  content: {
    backgroundColor: "rgba(56, 62, 64, 0.97)",
    border: "2px solid orange",
    color: "white",
    overflow: "initial",
    width: "calc(100vw - 60px)",
    height: "calc(100vh - 60px)",
    top: 30,
    left: 30,
    right: 30,
    bottom: 30,
    padding: 0
  },
  overlay: {
    zIndex: 10000
  }
};

// @ts-ignore
Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.65)";

type FullScreenModalPropsType = {
  children: JSX.Element[] | JSX.Element;
  onClose: ((
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void)
};

const FullScreenModal = ({ children, onClose }: FullScreenModalPropsType) => {
  const onRequestClose = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    if (window && window.document)
      window.document.body.style.overflow = "unset";
    onClose(e);
  };

  const onAfterOpen = () => {
    if (window && window.document)
      window.document.body.style.overflow = "hidden";
  };

  return (
    <Modal style={customStyles} onRequestClose={onClose} isOpen>
      <CloseButton onClick={onClose}>X</CloseButton>
      {children}
    </Modal>
  );
};

export default FullScreenModal;
