import axios from "axios";

export const GetFisheyePaths = async (path: string) => {
  const requestUrl =
    window.location.hostname === "localhost"
      ? `/api/files/getcontent/${path}/fisheye`
      : window.location.origin.replace("://", "://api.") +
        `/api/files/getcontent/${path}/fisheye`;

  try {
    const response = await axios.get(requestUrl);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};
