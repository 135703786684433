import axios from "axios";

export const ApiGetUserInfo = async (id: Number, token: string) => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/users/" + id : window.location.origin.replace("://", "://api.")+"/api/users/" + id;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  try {
    const response = await axios.get(requestUrl, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching data.");
  }
};
