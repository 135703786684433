import { UserType } from "../../functions/Cookie.js";
import {SET_PROGRESS, SET_USER, SET_HIDDEN, SET_SCREENMODE, SET_CAPTURESTATE, SET_PREVIEWNAME, SET_LASTCAPTURE, SET_DISKSPACE, SET_IMAGEUPLOADPROGRESS} from "../constants/action-types";

export function setProgress(payload: number) {
    return { type: SET_PROGRESS, payload }
  };

export function setUser(payload: UserType){
  return {type: SET_USER, payload}
}  

export function setHidden(payload: boolean){
  return {type: SET_HIDDEN, payload}
}  

export function setScreenMode(payload: string){
  return {type: SET_SCREENMODE, payload}
}  

export function setCaptureState(payload: string){
  return {type: SET_CAPTURESTATE, payload}
}  

export function setPreviewname(payload: string){
  return {type: SET_PREVIEWNAME, payload}
}  

export function setLastCapture(payload: string){
  return {type: SET_LASTCAPTURE, payload}
}  
export function setDiskspace(payload: string){
  return {type: SET_DISKSPACE, payload}
} 

export function setImageUploadProgress(payload: string) {
  return { type: SET_IMAGEUPLOADPROGRESS, payload }
};