import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootnode = document.querySelector("#initialState");

const initialStateString: string = (rootnode && rootnode.getAttribute("data-initialstate")) || "";
const initialState: { [key: string]: any } = rootnode && initialStateString ? JSON.parse(initialStateString) : {};

ReactDOM.hydrate(<App {...initialState} url={window.location.pathname} />,document.getElementById("app"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
