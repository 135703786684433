import axios from "axios";

export async function ApiGetImagesByCaptureId(captureName: string, token: string) {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/photos/" + captureName : window.location.origin.replace("://", "://api.")+"/api/photos/" + captureName;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  try {
    return await axios.get(requestUrl, config).then(res => {
      if (res.status === 200) {
        return res.data;
      }
    });
  } catch (error) {
    alert(error);
  }
}
