import React from "react";
import styled from "styled-components";

import logo from "../assets/images/logo.png";

import CircularProgressBar from "./CircularProgressBar";
import UserInfo from "./UserInfo";
import UserOverlay from "./UserOverlay";

const Container = styled.div`
  overflow: hidden;
  background-color: #383e40;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;

  & li {
    display: inline-block;
  }

  & li:first-child {
    float: left;
  }
  & li:last-child {
    float: right;
    width: 120px;
    margin-top: 10px;

    @media screen and (min-width: 767px) {
      margin-right: 25px;
    }
  }
`;

const Logo = styled.div`
  padding: 43px 0 15px 43px;
  background: #54493d;
  width: 40px;
  transform: rotate(-10deg);
  margin-left: -5px;
  margin-top: -20px;

  & img {
    width: 30px;
    transform: skewX(20deg);
    transform: rotate(10deg);
    position: relative;
    left: -10px;
  }
`;

const Hostlabel = styled.div`
  padding: 7px;
  border-radius: 15px;
  width: 100%;
  left: calc(50% - 57px);
  position: absolute;
  top: 20px;
  width: 100px;
  color: #c79256;
  background: #52493f;
`;

const TopBar = () => {
  return (
    <>
      <Container>
        <List>
          <li>
            <Logo>
              <a href="/">
                <img src={logo} alt="SewerMapper" />
              </a>
            </Logo>
          </li>
          <li>
            <Hostlabel>Scanner</Hostlabel>
          </li>
          <li>
            <CircularProgressBar />
            <UserInfo />
          </li>
        </List>
      </Container>
      <UserOverlay />
    </>
  );
};
export default TopBar;
