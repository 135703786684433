import axios from "axios";
import { CaptureType } from "../components/historycomponents/History.type";

export const  ApiGetCaptures = async (token: string): Promise<CaptureType[]> => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures" : window.location.origin.replace("://", "://api.")+"/api/captures";
  var config = {
    headers: { Authorization: "Bearer " + token }
  };

  try {
    return await (await axios.get(requestUrl, config)).data;
  } catch (error) {
    return [];
  }
};
