import React, { useState } from "react";
import styled from "styled-components";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const StyledCarousel = styled(Carousel)`
  & .thumbs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    height: 85px;
    max-height: calc(25vh) !important;
  }

  & .thumbs {
    height: 100%;
    display: flex;
    margin: 0;
  }

  & .thumb {
    border: 3px solid transparent;
    cursor: pointer;
  }

  & .thumb:hover {
    border: 3px solid rgba(218, 147, 85, 0.824);
  }

  & .thumb.selected {
    border: 3px solid #fa8c2a;
  }

  & .carousel-slider {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 80px - 25vh) !important;
  }

  & .slider-wrapper {
    display: flex;
  }

  & .slider-wrapper .slide {
    display: flex;
    justify-content: center;
    background: transparent;
  }

  & .control-arrow:before {
    width: 48px;
    height: 48px;
  }

  & .control-next.control-arrow:before {
    border: 0;
    content: url("../assets/images/ArrowRight.svg");
  }

  & .control-prev.control-arrow:before {
    border: 0;
    content: url("../assets/images/ArrowLeft.svg");
  }

  & .control-dots {
    padding: 0;
    position: relative;
    cursor: pointer;
    z-index: 2;
  }

  & .control-dots .dot {
    background: #fa8c2a;
  }

  & .carousel-slider .control-arrow:hover {
    background: none;
  }

  @media screen and (max-width: 700px) {
    & .control-arrow:before {
      width: 35px;
      height: 35px;
    }
  }
`;

const CarouselWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 20px;
`;

const carouselImageStyle: React.CSSProperties = {
  height: "100%",
  width: "fit-content !important",
  objectFit: "scale-down"
};

type ImageCarouselPropsType = {
  images: string[];
  selectedSlide: number;
};

const ImageCarousel = ({ images, selectedSlide }: ImageCarouselPropsType) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedSlide);

  const handleOnChange = React.useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );

  return (
    <CarouselWrapper>
      <StyledCarousel
        useKeyboardArrows={true}
        showArrows={true}
        showStatus={false}
        selectedItem={selectedIndex}
        onChange={handleOnChange}
      >
        {images.map((image: string) => {
          return (
            <img
              style={carouselImageStyle}
              key={image}
              src={window.location.hostname === "localhost" ? `/api/files/getfile/${image}` : window.location.origin.replace("://", "://api.") + `/api/files/getfile/${image}`}
              alt="image"
            />
          );
        })}
      </StyledCarousel>
    </CarouselWrapper>
  );
};

export default ImageCarousel;
