import axios from "axios";

export const ApiGetSingleCapture = async (token: string, id: string) => {
  const requestUrl =
    window.location.hostname === "localhost"
      ? `/api/captures/${id}`
      : window.location.origin.replace("://", "://api.") +
        `/api/captures/${id}`;
  var config = {
    headers: { Authorization: "Bearer " + token }
  };
  try {
    const response = await axios.get(requestUrl, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching Capture");
  }
};
