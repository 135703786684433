import React, { useState, useCallback } from "react";
import styled from "styled-components";

import FullScreenModal from "./FullScreenModal";
import ImageCarousel from "./ImageCarousel";

const StyledImage = styled.img`
  width: 90px;
  height: 75px;
  object-fit: contain;
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
`;

const Images = ({ images }: { images: string[] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const toggleModal = useCallback(
    e => {
      setIsModalOpen(!isModalOpen);
      if (e) {
        const parsedIndex = parseInt(e.target.id);

        setSelectedIndex(parsedIndex);
      }
    },
    [isModalOpen, setIsModalOpen, setSelectedIndex]
  );

  return (
    <>
      {images.map((image: string, index: number) => {
        return (
          <StyledImage
            src={window.location.hostname === "localhost" ? `/api/files/getfile/compressed/${image}` : window.location.origin.replace("://", "://api.") + `/api/files/getfile/compressed/${image}`}
            alt="image"
            id={index + ""}
            onClick={toggleModal}
            key={index}
          />
        );
      })}
      {isModalOpen && (
        <FullScreenModal onClose={toggleModal}>
          <ImageCarousel images={images} selectedSlide={selectedIndex} />
        </FullScreenModal>
      )}
    </>
  );
};

export default Images;
