import React, { useState, useEffect } from "react";
import styled from "styled-components";
import store from "../redux/store";
import { setHidden } from "../redux/actions/index";

const User = styled.div`
  display: inline-block;
  background-color: #333;
  margin: 5%;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #fa8c2a;
  text-decoration: none;
  border-radius: 50px;
  font-size: 13px;
  cursor: pointer;
  margin-top: 7px;
`;

type User = {
  firstname: string;
  lastname: string;
};

const UserInfo = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    //@ts-ignore
    window.store = store;
    const unsubscribe = store.subscribe(() => onStoreChange());
    setUser(store.getState().user);

    return unsubscribe;
  }, [store.getState().user]);

  const onStoreChange = () => {
    setUser(store.getState().user);
  };

  return (
    <>
      {user != null && (
        <User
          onClick={() => {
            store.dispatch(setHidden(!store.getState().navOverlayHidden));
          }}
        >
          {user.firstname?.substring(0, 1) + user.lastname?.substring(0, 1)}
        </User>
      )}
    </>
  );
};

export default UserInfo;
