import axios from 'axios';

export async function ApiPostNewProject(
    project: { name: string; location: string; captures?: string[]; companyId?: string },
    token: string,
) {
    const requestUrl =
        window.location.hostname === 'localhost'
            ? '/api/projects/'
            : window.location.origin.replace('://', '://api.') + '/api/projects/';
    var config = {
        headers: { Authorization: 'Bearer ' + token },
    };

    try {
        await axios.post(requestUrl, project, config).then(res => {
            if (res.status === 200) {
              if (typeof window !== 'undefined') window.location.href = window.location.origin
            }
        });
    } catch (error) {
        console.error(error);
    }
}
