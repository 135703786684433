import React, { useState } from "react";
import styled from "styled-components";

import LoginAnimation from "./LoginAnimation";
import Diskspace from "../common/Diskspace";

import store from "../../redux/store";
import { LoginCall } from "../../calls/LoginCall";
import { setUser } from "../../redux/actions/index";
import { SaveUser } from "../../functions/Cookie";
import { useDispatch } from "react-redux";

const LogoTag = styled.div`
  width: 300px;
  height: 100px;
  background: url("../assets/images/logotagline.png");
  position: relative;
  margin: 20px auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const LoginInput = styled.input`
  background: #26292b;
  color: #fa8c2a;
  border-radius: 35px;
  padding: 10px;
  text-align: center;
  border: 1px solid #fa8c2a;
  font-size: 16px;
  display: block;
  width: 40%;
  margin: 1em auto;
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus,
  &:-webkit-autofill-selected,
  &::placeholder {
    background-color: #26292b;
    color: #fa8c2a;
  }

  @media screen and (max-width: 900px) {
    width: 55%;
  }
  @media screen and (max-width: 600px) {
    width: 60%;
  }
`;

const LoginButton = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 2%;
  transition-duration: 0.4s;
  text-decoration: none;
  display: block;
  margin: 3em auto 1em auto;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }

  @media screen and (max-width: 640px) {
    width: 6em;
    height: 2em;
    margin: 2em auto 1em auto;
  }
`;

const LoginLink = styled.span`
  display: block;
  text-align: center;
  width: 70%;
  margin: 0 auto 50px;
  color: #ffc069;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

type User = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch()

  const userDictionary = (user: User) => {
    const dictionary = {
      firstname: user.firstname,
      id: user.id,
      lastname: user.lastname,
      token: user.token,
      username: user.username
    };
    return dictionary;
  };

  const sendCreds = async () => {
    setError(null);
    const [user, loginError] = await LoginCall({ username, password });
    setError(loginError);
    if (user !== null) {
      dispatch(setUser(user));
      const dictionary = userDictionary(user);
      SaveUser(dictionary);
    }
    return loginError;
  };

  const login = async (event: React.FormEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    const loginError = await sendCreds();
    if (loginError === null) setShowPopup(!showPopup);
  };

  return (
    <>
      <Diskspace />
      <LogoTag />
      <h4>{error}</h4>
      <form
        onSubmit={event => {
          login(event);
        }}
      >
        <LoginInput
          required
          placeholder="Username"
          onChange={event => {
            setUsername(event.target.value);
          }}
        />
        <LoginInput
          required
          type="password"
          placeholder="Password"
          onChange={event => {
            setPassword(event.target.value);
          }}
        />
        <LoginButton>Login</LoginButton>
      </form>
      {showPopup && <LoginAnimation />}
      <LoginLink>
        New to SewerMapper?<br></br>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://sewermapper.com/#book_a_demo"
        >
          Register
        </Link>{" "}
        or{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://sewermapper.com/"
        >
          more info
        </Link>
      </LoginLink>
    </>
  );
};

export default LoginForm;
