import axios from 'axios';

export const ApiAdminCheck = async (token: string) => {
    const requestUrl =
        window.location.hostname === 'localhost'
            ? '/api/users/isadmin'
            : window.location.origin.replace('://', '://api.') + '/api/users/isadmin';
    var config = {
        headers: { Authorization: 'Bearer ' + token },
    };

    try {
        return await (await axios.get(requestUrl, config)).data;
    } catch (error) {
        console.log('something went wrong fetching the data');
    }
};
