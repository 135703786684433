import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import ArrowUp from "../assets/images/ArrowLeft.svg";
import ArrowDown from "../assets/images/ArrowRight.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 6rem;
  color: white;
  z-index: 1;
  height: 100%;
  margin: auto 0;
  background: rgb(0, 0, 0, 0.7);
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);

  @media screen and (max-width: 426px){
    width: 5rem;
  }
  @media screen and (min-height: 1000px){
    width: 7rem;
  }
  @media screen and (min-width: 1500px){
    width: 8rem;
  }
}
`;

const CarouselImage = styled.div`
  width: 100%;
  & img {
    height: 50px;
    display: block;
    width: inherit;
    cursor: pointer;
    user-select: none;

    @media screen and (max-height: 670px) {
      height: 40px;
    }
    @media screen and (min-height: 1670px) {
      height: 65px;
    }
  }
`;

const CarouselArrow = styled.img`
  width: 3rem;
  cursor: pointer;
  user-select: none;
  transform: rotate(90deg);
`;

const CarouselArrowUp = styled.div`
  position: absolute;
  top: 2px;
`;
const CarouselArrowDown = styled.div`
  position: absolute;
  bottom: -3px;
`;

type CarouselItemPropsType = {
  imagePath: string;
  onClickImage: (imagePath: string) => void;
  handleEnterKeyOnImage: (imagePath: string) => void;
};

type VerticalCarouselPropsType = {
  onSelectImage: (imagePath: string) => void;
  images: string[];
};

const CarouselItem = ({
  imagePath,
  onClickImage,
  handleEnterKeyOnImage
}: CarouselItemPropsType) => {
  const handleOnClickImage = React.useCallback(() => {
    onClickImage(imagePath);
  }, [imagePath]);

  const handleKeyUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        handleEnterKeyOnImage(imagePath);
      }
    },
    [imagePath]
  );

  return (
    <CarouselImage
      onClick={handleOnClickImage}
      tabIndex={0}
      onKeyUp={handleKeyUp}
    >
      <img src={window.location.hostname === "localhost" ? `/api/files/getfile/compressed/${imagePath}` : window.location.origin.replace("://", "://api.") + `/api/files/getfile/compressed/${imagePath}`} alt={imagePath} />
    </CarouselImage>
  );
};

const VerticalCarousel = ({
  onSelectImage,
  images
}: VerticalCarouselPropsType) => {
  const [startOfCarousel, setStartOfCarousel] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [endOfCarousel, setEndOfCarousel] = useState(
    Math.min(5, images.length)
  );

  useEffect(() => {
    const handleHeight = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleHeight);

    setEndOfCarousel(Math.min(5, images.length));
    height > 900 && setEndOfCarousel(Math.min(7, images.length));
    height > 1200 && setEndOfCarousel(Math.min(8, images.length));
    height > 1500 && setEndOfCarousel(Math.min(10, images.length));

    return () => {
      window.removeEventListener("resize", handleHeight);
    };
  }, [window.innerHeight]);

  const showNextImage = useCallback(() => {
    setStartOfCarousel(startOfCarousel + 1);
    setEndOfCarousel(endOfCarousel + 1);
  }, [startOfCarousel, endOfCarousel]);

  const showPrevImage = useCallback(() => {
    setStartOfCarousel(startOfCarousel - 1);
    setEndOfCarousel(endOfCarousel - 1);
  }, [startOfCarousel, endOfCarousel]);

  const slicedImages = React.useMemo(() => {
    return images ? images.slice(startOfCarousel, endOfCarousel) : [];
  }, [startOfCarousel, endOfCarousel]);

  const handlePressOnArrowUp = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        if (startOfCarousel !== 0) {
          showPrevImage();
        }
      }
    },
    [showPrevImage, startOfCarousel]
  );

  const handlePressOnArrowDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        if (endOfCarousel !== images.length) {
          showNextImage();
        }
      }
    },
    [showNextImage, endOfCarousel, images]
  );

  return (
    <Container>
      {startOfCarousel !== 0 && (
        <CarouselArrowUp tabIndex={0} onKeyUp={handlePressOnArrowUp}>
          <CarouselArrow src={ArrowUp} alt="ArrowUp" onClick={showPrevImage} />
        </CarouselArrowUp>
      )}
      {slicedImages.map(image => (
        <CarouselItem
          key={image}
          imagePath={image}
          onClickImage={onSelectImage}
          handleEnterKeyOnImage={onSelectImage}
        />
      ))}
      {endOfCarousel !== images.length && (
        <CarouselArrowDown tabIndex={0} onKeyUp={handlePressOnArrowDown}>
          <CarouselArrow
            src={ArrowDown}
            alt="ArrowDown"
            onClick={showNextImage}
          />
        </CarouselArrowDown>
      )}
    </Container>
  );
};

export default VerticalCarousel;
