import axios from 'axios';
import { SetStateAction } from "react";

type CaptureType = {
  key: number;
  id: number;
  dateTime : string | Date;
  deviceName: string;
  location: string;
  syncStatus: string;
  processStatus: string;
  fullName: string;
  userId: number;
  path: string;
}

type GetCaptureListType = {
  setCaptureList:React.Dispatch<SetStateAction<CaptureType[]>>;
}

export  const GetCaptureList = async (event:GetCaptureListType) => {
  const requestUrl = '/capture_list'
  const response = await axios.get(requestUrl);
  event.setCaptureList(JSON.parse(response.data))
}
