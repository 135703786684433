import React, { useState } from "react";
import styled from "styled-components";

const Measurements = styled.div`
  width: calc(100% - 40px);
  object-fit: contain;
  margin-top: 40px;
  margin-bottom: 40px;
  margin: auto;
  text-align: center;

  @media screen and (min-width: 1000px) {
    width: calc(50% - 40px);
    display: inline-block;
    margin: auto;
  }

  & img {
    width: 100%;
    object-fit: contain;
    height: 45vh;
  }
`;
const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 2%;
  transition-duration: 0.4s;
  text-decoration: none;
  display: block;
  margin: 1% auto 1em auto;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }
`;

type PropsType = {
  path: string;
  event: () => void;
};

const MeasurementViewer = (props: PropsType) => {
  const [error, setError] = useState(false);
  return (
    <Measurements>
      {!error ? (
        <img
          src={props.path}
          alt="measurements"
          onError={() => {
            setError(true);
          }}
        ></img>
      ) : (
        <p>Cannot display measurements</p>
      )}
      <Button onClick={props.event}>View model</Button>
    </Measurements>
  );
};

export default MeasurementViewer