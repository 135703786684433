import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

const BackButtonLink = styled.a`
    flex: 1;
    width: fit-content;
    display: flex;
    padding-left: 5%;
    align-items: center;
    text-decoration: none;
`;

const Arrow = styled.div`
    flex-direction: row;
    width: 6px;
    height: 6px;
    box-sizing: border-box;
    transform: rotate(-135deg);
    cursor: pointer;
    margin-left: 10px;
    margin-top: 3px;
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-width: 3px 3px 0 0;
        border-style: solid;
        border-color: ${colors.mainOrange};
        transition: 0.2s ease;
        display: block;
        transform-origin: 100% 0;
    }

    &::after {
        content: '';
        float: left;
        position: relative;
        top: -100%;
        width: 100%;
        height: 100%;
        border-width: 0 0.8vmin 0 0;
        border-style: solid;
        border-color: ${colors.mainOrange};
        transform-origin: 100% 0;
        transition: 0.2s ease;
    }

    @media screen and (min-height: 1050px) {
        &::after {
            content: none;
        }
    }
`;

const AppendText = styled.span`
    color: ${colors.mainOrange};
    flex-direction: row;
    margin-left: 10px;
    transition: 0.2s ease;
    &:hover{
      margin-left: 5px;
      transition: 0.2s ease;
    }
`;
type BackButtonPropsType = {
    href?: string;
    onClick?: () => void;
    appendText?: string;
};
const BackButton = ({ href, onClick, appendText }: BackButtonPropsType) => {
    return (
        <BackButtonLink
            href={href ?? '#'}
            onClick={event => {
                if (onClick) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (onClick) onClick();
                }
            }}
        >
            <Arrow />
            {appendText && <AppendText>{appendText}</AppendText>}
        </BackButtonLink>
    );
};

export default BackButton;
