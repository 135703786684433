import React, { useState, useEffect } from "react";
import styled from "styled-components";

import store from "../../redux/store";
import { ApiPutCapture } from "../../calls/ApiPutCapture";
import { GetCaptureList } from "../../calls/GetCaptureList";

const Container = styled.div`
  padding-top: 9vh;
  text-align: center;
`;

const Background = styled.div`
  background-image: url(../assets/images/captureScreenBackgroungImg.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const CaptureBlock = styled.div`
  height: 70vh;
`;
const Label = styled.label`
  color: #ffc069;
  font-size: 25pt;
  display: block;
  padding-top: 10%;
`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 2%;
  transition-duration: 0.4s;
  text-decoration: none;
  margin: 6% 3% 1em 3%;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }
`;

const MapView = styled.div`
  position: absolute;
  right: 30px;
  margin-top: -6em;

  @media screen and (max-width: 750px) {
    margin-top: -3em;
  }
  @media screen and (max-height: 641px) {
    margin-top: 0em;
  }
`;

const Map = styled.div`
  border: 2px solid #fa8c2a;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: white;
  @media screen and (max-width: 750px) {
    width: 100px;
    height: 100px;
  }
`;

type CaptureType = {
  name: string;
};

export default () => {
  const [capture, setCapture] = useState({} as CaptureType);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => null);
    const getData = async () => {
      const currentCapture = await store.getState().lastcapture;
      await setCapture(currentCapture);
      ApiPutCapture(currentCapture, store.getState().user.token);
    };
    getData();
    return unsubscribe;
  }, []);

  return (
    <Container>
      <Background>
        {capture ? (
          <>
            <CaptureBlock>
              <Label>Capture name: {capture && capture.name}</Label>·
              <Label>What do you want to do?</Label>
              <a href={`/preview/${capture}`}>
                <Button>Preview</Button>
              </a>
              <a href="/capture">
                <Button>Restart Capture</Button>
              </a>
            </CaptureBlock>
            <MapView>
              <Map />
            </MapView>
          </>
        ) : (
          <p>Something went wrong, capture not saved</p>
        )}
      </Background>
    </Container>
  );
};
