import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Images from "./Images";
import Camera from "./common/Camera";

import store from "../redux/store";
import { ApiPostPhoto } from "../calls/ApiPostPhoto";
import { ApiGetImagesByCaptureId } from "../calls/ApiGetImagesByCaptureId";

const UploadSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

type CaptureType = {
  key: number;
  id: number;
  dateTime: string | Date;
  deviceName: string;
  location: string;
  syncStatus: string;
  processStatus: string;
  fullName: string;
  userId: number;
  path: string;
};

type PropsType = {
  capture: CaptureType;
};

const ImageUploadSection = (props: PropsType) => {
  const [user, setUser] = useState({} as UserType);
  const [images, setImages] = useState<string[]>([]);
  const [capture, setCapture] = useState(props.capture);
  const [change, setChange] = useState(false);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());
    const getData = async () => {
      const receivedImages = await ApiGetImagesByCaptureId(
        props.capture.fullName,
        store.getState().user.token
      );
      setImages(receivedImages);
      setChange(false);
    };
    getData();
    return unsubscribe;
  }, [change]);

  const onStoreChange = async () => {
    const currentUser = await store.getState().user;
    setUser(currentUser);
  };
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    ApiPostPhoto(
      capture.fullName,
      store.getState().user.token,
      file
    )
    .then(() => {
      setChange(true);
    });
  };

  const removeImage = (imagePath: string) => {
    const captureImage = images.filter((image: string) => image !== imagePath);
    setImages(captureImage);
  };

  return (
    <UploadSection>
      <Camera onChange={onChange} />
      {images && <Images images={images} />}
    </UploadSection>
  );
};

export default ImageUploadSection;
