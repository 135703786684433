import axios from "axios";

type LoginCredentials = {
  username: string;
  password: string;
};

export const LoginCall = async (creds: LoginCredentials) => {
  const requestUrl =
    window.location.hostname === "localhost" || window.location.port === '3000'
      ? "/api/authenticate"
      : window.location.origin.replace("://", "://api.") + "/api/authenticate";
  var postVariables = {
    username: creds.username,
    password: creds.password
  };
  try {
    const res = await axios.post(requestUrl, postVariables);
    if (res.status !== 204) {
      var response = await res.data;
      if (response === null) {
        return ["There was a problem, try again later.", null];
      } else {
        return [response, null];
      }
    } else {
      return [null, "Wrong username or password."];
    }
  } catch (error) {
    return [null, "Error, can't connect to server"];
  }
};
