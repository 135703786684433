import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";

import FullScreenModal from "./FullScreenModal";
import { ApiPutCaptureComment } from "../calls/ApiPutCaptureComment";

const Container = styled.div`
  text-align: center;
`;

const Form = styled.form`
  text-align: center;
  margin-top: 10vh;

  & label {
    color: #fa8c2a;
    font-weight: bold;
    font-size: xx-large;
    margin-bottom: 15px;
    display: block;

    @media screen and (max-width: 400px) {
      font-size: x-large;
    }
  }
`;

const Comment = styled.div`
  width: 60%;
  margin: 5% auto;

  & h3,
  h5 {
    color: #fa8c2a;
    text-align: center;
  }
`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  margin: 2em 1em auto;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }

  @media screen and (max-width: 400px) {
    width: 171px;
    margin: 5px auto;
    height: 35px;
  }

  ${props =>
    // @ts-ignore
    props.primary &&
    css`
      @media screen and (min-width: 1000px) {
        position: relative;
        bottom: 77px;
        margin: 0px;
      }
      @media screen and (max-width: 400px) {
        margin: 1em 1em auto;
        height: 44px;
      }
    `}
`;

const InputArea = styled.textarea`
  display: block;
  width: fit-content;
  margin: auto;
  font-size: x-large;
  color: #fa8c2a;
  border-radius: 10px;
  border: #fa8c2a 2px solid;
  background-color: initial;

  @media screen and (max-width: 770px) {
    width: 90%;
  }
`;

const CaptureComment = React.memo((props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState(props.comment);
  const [currentComment, setCurrentComment] = useState(props.comment);
  const [error, setError] = useState(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [setIsModalOpen, isModalOpen]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const response = await ApiPutCaptureComment(
        props.id,
        props.token,
        comment
      );
      response || response === ""
        ? setCurrentComment(response)
        : setError(true);
    },
    [comment]
  );

  return (
    <Container>
      {/* @ts-ignore */}
      <Button primary onClick={toggleModal}>
        {currentComment ? "View/Edit" : "Add"} Remark
      </Button>
      {isModalOpen && (
        <FullScreenModal onClose={toggleModal}>
          <Form onSubmit={handleSubmit}>
            <label htmlFor="comment">
              {currentComment ? "Edit" : "Add"} Remark
            </label>
            <InputArea
              id="comment"
              rows={4}
              cols={50}
              onChange={event => {
                setComment(event.target.value);
              }}
              value={comment}
            ></InputArea>
            <Button onClick={toggleModal}>Cancel</Button>
            <Button type="submit">Save</Button>
          </Form>
          <Comment>
            {error && <h5>Error, unable to add/edit comment</h5>}
            <h3>{!currentComment && "No"} Remark</h3>
            <h4>{currentComment}</h4>
          </Comment>
        </FullScreenModal>
      )}
    </Container>
  );
});

export default CaptureComment;
