import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import ApiGetProjects from '../../calls/ApiGetProjects';
import store from '../../redux/store';
import { GetUserFromCookie, UserType } from '../../functions/Cookie';
import { ProjectType } from './History.type';

import HistoryBlockList from './HistoryBlockList';
import Spinner from '../common/Spinner';
import LoadingAnimation from '../common/LoadingAnimation';
import ProjectsBlockList from './ProjectsBlockList';

const Container = styled.div`
    box-sizing: border-box;
    margin: 0px 5%;
`;

const LoadMoreButton = styled.button`
    font-size: 15px;
    color: white;
    text-align: center;
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin: 30px auto;
    display: block;
    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

const Projects = (props: { grid: number; search: string }) => {
    const [projects, setProjects] = useState<ProjectType[]>([]);
    const [isLoadingInitial, setIsLoadingInitial] = useState(true);

    useEffect(() => {
        const cookieUser = GetUserFromCookie();
        ApiGetProjects(cookieUser?.token).then(fetchedProjects => {
            setProjects(fetchedProjects);
            setIsLoadingInitial(false);
        });
    }, []);

    return (
        <>
            {isLoadingInitial ? (
                <Container>
                    <LoadingAnimation />
                </Container>
            ) : (
                <Container>{projects && <ProjectsBlockList projects={projects} search={props.search} />}</Container>
            )}
        </>
    );
};

export default Projects;
