import React, { useState } from 'react';
import styled from 'styled-components';

import Viewer from './Viewer';

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(56, 62, 64, 0.7);
    top: 0;
    left: 0;
    z-index: 5;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

const Overlay = styled.div`
    background-color: #383e40;
    width: 99vw;
    height: 99vh;
    z-index: 2;
`;

const CloseButton = styled.span`
    position: relative;
    cursor: pointer;
    background-color: #383e40;
    padding: 0.5px 8px;
    color: #fa8c2a;
    left: calc(100% - 80px);
    top: 10px;
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid #fa8c2a;
    z-index: 6;
    &:hover {
        color: #fff;
        border: 1px solid #fff;
        transition: color 0.5s;
        transition: border 0.5s;
    }
`;

const Button = styled.button`
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    text-align: center;
    height: 44px;
    width: auto;
    padding-left: 33px;
    padding-right: 33px;
    width: 220px;
    border-radius: 25px;
    border: none;
    background: #fa8c2a;
    cursor: pointer;
    margin: 3%;
    transition-duration: 0.4s;
    text-decoration: none;

    &:hover {
        background-color: rgba(255, 131, 23, 0.8);
    }
`;

type CaptureType = {
    key: number;
    id: number;
    dateTime: string | Date;
    deviceName: string;
    location: string;
    syncStatus: string;
    processStatus: string;
    fullName: string;
    userId: number;
    path: string;
};

type PropsType = {
    closeNav: (overlayState: boolean, buttonState: boolean) => void;
    data: CaptureType;
    showOverlay: boolean;
    showButton: boolean;
};

const Overlay3DViewer = (props: PropsType) => {
    const [showOverlay, setShowOverlay] = useState(props?.showOverlay || false);
    const [showButton, setShowButton] = useState(props?.showButton || false);
    const [show3dObj, setShow3dObj] = useState(true);

    const openNav = () => {
        setShowOverlay(true);
        setShowButton(true);
        setShow3dObj(true);
    };

    const closeNav = () => {
        setShowOverlay(false);
        setShowButton(false);
        setShow3dObj(false);
        props.closeNav(false, false);
    };

    return (
        <>
            <div>
                <script src="../assets/lib/three.js"></script>
                <script src="../assets/lib/PLYLoader.js"></script>
                <script src="../assets/lib/OrbitControls.js"></script>
                <script src="../assets/lib/WebGL.js"></script>
                <script src="../assets/lib/stats.min.js"></script>
            </div>
            {showOverlay && (
                <Container>
                    <Overlay>
                        <CloseButton onClick={closeNav}>X</CloseButton>
                        {show3dObj && <Viewer path={props.data.path} />}
                        {/* <a href={`https://3d.sewermapper.eu?name=${props.data.fullName}`} target="_blank">
                            <Button>Advanced</Button>
                        </a> */}
                    </Overlay>
                </Container>
            )}
        </>
    );
};

export default Overlay3DViewer;
