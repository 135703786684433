import axios from "axios";

export const GetImage = async () => {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/captures/imagepreview" : window.location.origin.replace("://", "://api.")+"/api/captures/imagepreview";

  try {
    const response = await axios.get(requestUrl);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log("something went wrong fetching the previewimage");
  }
};
