import axios from "axios";

export async function ApiGetAvailableDiskSpace() {
  const requestUrl = window.location.hostname === 'localhost' ? "/api/hardware/diskspace" : window.location.origin.replace("://", "://api.")+"/api/hardware/diskspace";
  try {
    return axios.get(requestUrl).then(res => {
      if (res.status === 200) {
        return res.data;
      }
    });
  } catch (error) {
    console.log("something went wrong fetching the data");
  }
}
