import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { MakeCaptureStop } from "../../calls/MakeCaptureStop";
import { GetGPS } from "../../calls/GetGPS";
import { GetImage } from "../../calls/GetImage";
import { GetCaptureList } from "../../calls/GetCaptureList";
import { ApiPostCapture } from "../../calls/ApiPostCapture";
import { GetUserFromCookie } from "../../functions/Cookie";
import StartProcess from "../../calls/StartProcess";

const Container = styled.div`
  margin-top: 20vh;
  text-align: center;
`;

const ScanName = styled.p`
  color: #fa8c2a;
  font-size: 20px;
`;

const PreviewBlock = styled.div`
  border: 0.5px solid #fa8c2a;
  height: 45vh;
  width: 100%;
`;

const Preview = styled.div`
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const CaptureStatus = styled.div`
  border: 0.5px solid #fa8c2a;
  background-color: #26292b;
  height: 25px;
  width: 140px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  margin-top: 15px;
`;

const StatusText = styled.span`
  color: #fa8c2a;
  position: relative;
  top: 3px;
  padding-left: 20px;
  font-size: 12px;
`;

const StatusRedCircle = styled.span`
  border-radius: 50%;
  height: 17px;
  width: 17px;
  background-color: rgb(250, 42, 42);
  float: right;
  margin: 4px;
`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 2%;
  transition-duration: 0.4s;
  text-decoration: none;
  display: block;
  margin: 6% auto 1em auto;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }

  @media screen and (max-width: 450px) {
    width: 170px;
    font-size: medium;
    margin-top: 100px;
  }
`;

const MapView = styled.div`
  position: absolute;
  right: 30px;
  margin-top: -6em;

  @media screen and (max-width: 750px) {
    margin-top: -3em;
  }
`;

const Map = styled.div`
  border: 2px solid #fa8c2a;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: white;
  @media screen and (max-width: 750px) {
    width: 100px;
    height: 100px;
  }
`;

type ActionType = {
  type: string;
  payload: string;
};

type PropsType = {
  captureState: string;
  dispatch: (arg: ActionType) => void;
};

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

type CaptureType = {
  key: number;
  id: number;
  dateTime: string | Date;
  deviceName: string;
  location: string;
  syncStatus: string;
  processStatus: string;
  fullName: string;
  userId: number;
  path: string;
};

const CaptureRunning = (props: PropsType) => {
  const [name, setName] = useState("");
  const [user, setUser] = useState({} as UserType);
  const [capture, setCapture] = useState("");
  const [captureList, setCaptureList] = useState<CaptureType[]>([]);
  const [refreshID, setRefreshID] = useState("");
  const [previewID, setPreviewID] = useState("");
  const [machineState, setMachineState] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [srcHash, setSrcHash] = useState(Date.now());
  const [image, setImage] = useState("");
  const [gpsData, setGpsData] = useState({
    lon: "",
    lat: ""
  });

  useEffect(() => {
    const checkGPSData = (): void => {
      if (gpsData.lon == null || gpsData.lat == null) {
        gpsData.lon = "NA";
        gpsData.lat = "NA";
      }
    };
    GetGPS({ setGpsData }).then(
      // @ts-ignore
      checkGPSData()
    );
    setUser(GetUserFromCookie());
    GetCaptureList({ setCaptureList });
    const InitialiseImages = async () => {
      const response = await GetImage();
      const resposeImage = response!.data;
      setImage(resposeImage);
    };
    setInterval(InitialiseImages(), 1000);
  }, []);

  const stopCapture = () => {
    ApiPostCapture(name, 1, user.token, { setCapture });
    GetCaptureList({ setCaptureList });
    MakeCaptureStop({ setMachineState });
    setTimeout(() => {
      StartProcess(captureList[0].path, 1);
    }, 500);
    props.dispatch({ type: "SET_CAPTURESTATE", payload: "CAPTURE_STOP" });
  };

  return (
    <Container>
      <ScanName>{name}</ScanName>
      <PreviewBlock>
        <CaptureStatus>
          <StatusText>CAPTURING</StatusText>
          <StatusRedCircle />
        </CaptureStatus>
        <Preview>
          <Image src={`data:image/jpg;base64,${image}`} alt="Stream" />
        </Preview>
      </PreviewBlock>
      <MapView>
        <Map />
        {gpsData.lat}
        {gpsData.lon}
      </MapView>
      <Button onClick={stopCapture}>Stop capturing</Button>
    </Container>
  );
};

function mapStateToProps(state: PropsType) {
  return {
    captureState: state.captureState
  };
}

export default connect(mapStateToProps)(CaptureRunning);
