import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Toggle from "../common/Toggle";

import store from "../../redux/store";
import { MakeCaptureStart } from "../../calls/MakeCaptureStart";
import { StartCaptureCheck } from "../../functions/StartCaptureCheck";
import { setLastCapture } from "../../redux/actions/index";

const Container = styled.div`
  padding-top: 10vh;
  text-align: center;
`;

const CaptureInput = styled.input`
  background: #26292b;
  margin-bottom: 5%;
  color: #ffc069;
  border-radius: 35px;
  padding: 10px;
  text-align: center;
  border: 1px solid #fa8c2a;
  font-size: 16px;
  display: block;
  width: 40%;
  margin: 1em auto;

  &:: placeholder {
    opacity: 50%;
  }
`;

const Background = styled.div`
  background-image: url(../assets/images/captureScreenBackgroungImg.jpg);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
`;

const MachineWarning = styled.h4`
  font-size: 30px;
`;

const Label = styled.label`
  color: #ffc069;
  font-size: 25pt;
  margin-top: 1em;
`;

//@ts-ignore
const Spacing = styled.div`margin-top: ${props => (props.main ? "8%" : "1%")};`;

const Button = styled.button`
  font-size: 20px;
  color: white;
  text-align: center;
  height: 44px;
  width: auto;
  padding-left: 33px;
  padding-right: 33px;
  width: 220px;
  border-radius: 25px;
  border: none;
  background: #fa8c2a;
  cursor: pointer;
  margin: 2%;
  transition-duration: 0.4s;
  text-decoration: none;
  display: block;
  margin: 5em auto 1em auto;

  &:hover {
    background-color: rgba(255, 131, 23, 0.8);
  }
`;

type ActionType = {
  type: string;
  payload: string;
};

type PropsType = {
  captureState: string;
  dispatch: (arg: ActionType) => void;
};

type UserType = {
  firstname: string;
  lastname: string;
  username: string;
  id: number;
  token: string;
};

const CaptureStart = (props: PropsType) => {
  const [name, setName] = useState("");
  const [user, setUser] = useState<UserType>({} as UserType);
  const [capture, setCapture] = useState("");
  const [machineState, setMachineState] = useState<string | null>(null);
  const [refreshID, setRefreshID] = useState(0);
  const [selectedType, setSelectedType] = useState("std");
  const [startButton, setStartButton] = useState("visible");

  useEffect(() => {
    const unsubscribe = store.subscribe(() => onStoreChange());
    const getData = async () => {
      const currentUser = await store.getState().user;
      setUser(currentUser);
    };
    getData();
    if (capture !== store.getState().lastcapture && capture !== null) {
      store.dispatch(setLastCapture(capture));
    }
    return unsubscribe;
  });

  const onStoreChange = async () => {
    const currentUser = store.getState().user;
    setUser(currentUser);
  };

  const GetMachineState = () => {
    if (machineState == null) {
      return ;
    } else {
      return machineState.toString();
    }
  };

  const getType = (type: string) => {
    setSelectedType(type);
  };

  const startCapture = async () => {
    await MakeCaptureStart(
      { name, setStartButton, setMachineState, setCapture, props },
      user.token
    );
    if (machineState !== null) {
      StartCaptureCheck({ setRefreshID, setMachineState });
    }
  };

  return (
    <Background>
      <Container>
        <MachineWarning>{GetMachineState()}</MachineWarning>
        {startButton === "visible" && (
          <>
            <Label>Capture Name</Label>
            <CaptureInput
              required
              placeholder="Enter a Name"
              onChange={event => {
                setName(event.target.value);
              }}
            />
            {/* @ts-ignore */}
            <Spacing main>
              <Label>Choose type of capture</Label>
              <Spacing>
                <Toggle
                  class="darkmode"
                  callback={getType}
                  values={["std", "pic"]}
                  active="Standard (3D + Images)"
                  items={["Standard (3D + Images)", "Image Only"]}
                />
              </Spacing>
            </Spacing>
            <Button onClick={startCapture}>Start capturing</Button>
          </>
        )}
      </Container>
    </Background>
  );
};

function mapStateToProps(state: PropsType) {
  return {
    captureState: state.captureState
  };
}

export default connect(mapStateToProps)(CaptureStart);
